import * as moment from "moment";

export const initialFilterState = () => {
  const startDate = moment().startOf("month").hours(0).minutes(0).seconds(0).toDate();
  const endDate = moment().hours(23).minutes(59).seconds(59).toDate();

  return {
    startDate,
    endDate,
    page: 1,
    perPage: 10,
  };
};
