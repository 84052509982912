import { ShiftStatus } from "enums";

export const initialFilterState = () => ({
  startDate: null,
  endDate: null,
  cashiers: {
    value: [],
    isAll: true,
  },
  statuses: {
    isSelectedAll: true,
    value: [ShiftStatus.Started, ShiftStatus.Ended, ShiftStatus.Closed],
  },
  page: 1,
  perPage: 10,
});
