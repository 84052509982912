import React, { useContext } from "react";
import { Intro } from "components/commons";
import locale from "localization";
import { salesSummaryColumns } from "./sales-summary-column";
import { initialFilterState } from "./sales-summary-filter.state";
import { mapFilterToRequest, mapDataToList } from "./sales-summary.mapper";
import useDataTable from "hooks/useDataTable";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import { StationContext } from "contexts";
import { getDailyRecordsByStation } from "apis/daily-record.api";
import SalesSummaryFilter from "./sales-summary-filter";

import styles from "./sales-summary.module.scss";
import useExport from "hooks/useExport";
import { generateReport } from "apis/generate-report.api";

const SalesSummaryModule = () => {
  const { station } = useContext(StationContext);

  const { filter, table } = useDataTable({
    api: {
      api: getDailyRecordsByStation,
      params: {
        page: 1,
        perPage: 10,
        stationCode: station.stationCode,
      },
      mapper: (param) => {
        return param;
      },
    },
    filter: { initialState: initialFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "dailyRecords",
      mapper: (dailyRecords) =>
        mapDataToList({
          dailyRecords,
        }),
      columns: salesSummaryColumns,
    },
  });

  const exports = useExport({
    api: generateReport,
    hasModal: false,
    reportType: "daily-record",
    mappedFilterState: {
      ...filter?.mappedFilterState,
      stationCode: station.stationCode,
    },
  });

  return (
    <>
      <Intro title={locale.salesSummaryReport} />
      <SalesSummaryFilter {...filter} {...exports} />
      <div className={styles.table}>
        <DataTableV2 {...table} />
      </div>
    </>
  );
};

export default SalesSummaryModule;
