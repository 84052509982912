import locale from "localization";
import styles from "./shift-records-details-sales-inventory.module.scss";
import { Title } from "components/commons";
import { fuelVarianceMapper } from "./shift-records-details-sales-inventory.mapper";
import { fuelVarianceColumn } from "./shift-records-details-sales-inventory-column";
import { formatVolume } from "utils";
import Big from "big.js";
import { ShiftStatus, ProductCodeEnum, FormMode } from "enums";
import { filter, sumBy } from "lodash";
import useDataTable from "hooks/useDataTable";
import { initialFilterState } from "modules/shift-records/shift-records-filter.state";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import { stringToDecimal } from "utils/text.utils";

const ShiftDetailsFuelVariance = ({ shift, progress, form, pageMode }) => {
  const { fields } = form;
  const delivery = shift?.delivery;
  const transactionItem = shift?.transactionItem;
  const shiftCompleted = ({ shift, progress }) => {
    const status = shift?.shift.status;
    return [ShiftStatus.Ended, ShiftStatus.Closed].includes(status) && progress === 100;
  };
  const isShiftCompleted = shiftCompleted({ shift: shift, progress: progress });

  let initForm = {
    beginningDipstick: "N/A",
    delivery: 0,
    litersAvailableForSale: "N/A",
    litersSoldPerTotalizer: 0,
    calibration: "",
    returns: "",
    pumpError: "",
    endingInventoryPerTotalizer: 0,
    endingInventoryPerDipstick: "N/A",
    variance: "N/A",
    deliveryVariance: 0,
    totalVariance: "N/A",
  };

  let diesel = { ...initForm };
  let gas91 = { ...initForm };
  let gas95 = { ...initForm };
  let gas97 = { ...initForm };

  const computeDeliveryVariance = (e) => {
    return Number(
      Big(e.quantityAfterReceivingL || 0)
        .minus(e.quantityBeforeReceivingL || 0)
        .minus(e.quantityDeliveredL || 0)
    );
  };

  const formatDipstick = (cm, l) => {
    return `${formatVolume(cm, "cm")} / ${formatVolume(l, "L")}`;
  };

  const formatValues = (value) => {
    if (!isShiftCompleted) {
      return "";
    }

    if (value === "N/A") {
      return value;
    }

    return formatVolume(value);
  };

  const setBeginningDipstick = () => {
    if (!isShiftCompleted) {
      diesel.beginningDipstick = "";
      gas91.beginningDipstick = "";
      gas95.beginningDipstick = "";
      gas97.beginningDipstick = "";
      return;
    }

    if (pageMode === FormMode.Edit && fields?.isReadingDipstickBeginning.value) {
      return;
    }

    if (pageMode === FormMode.View && !shift?.shift.isReadingDipstickBeginning) {
      return;
    }

    diesel.beginningDipstick = formatDipstick(
      stringToDecimal(fields?.beginningDieselCm.value) || 0,
      stringToDecimal(fields?.beginningDiesel.value) || 0
    );

    gas91.beginningDipstick = formatDipstick(
      stringToDecimal(fields?.beginningGas91Cm.value) || 0,
      stringToDecimal(fields?.beginningGas91.value) || 0
    );

    gas95.beginningDipstick = formatDipstick(
      stringToDecimal(fields?.beginningGas95Cm.value) || 0,
      stringToDecimal(fields?.beginningGas95.value) || 0
    );

    gas97.beginningDipstick = formatDipstick(
      stringToDecimal(fields?.beginningGas97Cm.value) || 0,
      stringToDecimal(fields?.beginningGas97.value) || 0
    );
  };

  const setDelivery = () => {
    diesel.delivery = delivery.totalDieselDelivered ?? 0;
    gas91.delivery = delivery.totalGas91Delivered ?? 0;
    gas95.delivery = delivery.totalGas95Delivered ?? 0;
    gas97.delivery = delivery.totalGas97Delivered ?? 0;
  };

  const setLitersAvailableForSale = () => {
    if (pageMode === FormMode.Edit && fields?.isReadingDipstickBeginning.value) {
      return;
    }

    if (pageMode === FormMode.View && !shift?.shift.isReadingDipstickBeginning) {
      return;
    }

    diesel.litersAvailableForSale = Number(
      Big(stringToDecimal(fields?.beginningDiesel.value) || 0).add(delivery.totalDieselDelivered)
    );
    gas91.litersAvailableForSale = Number(
      Big(stringToDecimal(fields?.beginningGas91.value) || 0).add(delivery.totalGas91Delivered)
    );
    gas95.litersAvailableForSale = Number(
      Big(stringToDecimal(fields?.beginningGas95.value) || 0).add(delivery.totalGas95Delivered)
    );
    gas97.litersAvailableForSale = Number(
      Big(stringToDecimal(fields?.beginningGas97.value) || 0).add(delivery.totalGas97Delivered)
    );
  };

  const setLitersSoldPerTotalizer = () => {
    diesel.litersSoldPerTotalizer = transactionItem.totalDieselSold ?? 0;
    gas91.litersSoldPerTotalizer = transactionItem.totalGas91Sold ?? 0;
    gas95.litersSoldPerTotalizer = transactionItem.totalGas95Sold ?? 0;
    gas97.litersSoldPerTotalizer = transactionItem.totalGas97Sold ?? 0;
  };

  const setEndingInventoryPerDipstick = () => {
    if (!isShiftCompleted) {
      diesel.endingInventoryPerDipstick = "";
      gas91.endingInventoryPerDipstick = "";
      gas95.endingInventoryPerDipstick = "";
      gas97.endingInventoryPerDipstick = "";
      return;
    }

    if (pageMode === FormMode.Edit && fields?.isReadingDipstickEnding.value) {
      return;
    }

    if (pageMode === FormMode.View && !shift?.shift.isReadingDipstickEnding) {
      return;
    }

    diesel.endingInventoryPerDipstick = formatDipstick(
      stringToDecimal(fields?.endingDieselCm.value) || 0,
      stringToDecimal(fields?.endingDiesel.value) || 0
    );

    gas91.endingInventoryPerDipstick = formatDipstick(
      stringToDecimal(fields?.endingGas91Cm.value) || 0,
      stringToDecimal(fields?.endingGas91.value) || 0
    );
    gas95.endingInventoryPerDipstick = formatDipstick(
      stringToDecimal(fields?.endingGas95Cm.value) || 0,
      stringToDecimal(fields?.endingGas95.value) || 0
    );
    gas97.endingInventoryPerDipstick = formatDipstick(
      stringToDecimal(fields?.endingGas97Cm.value) || 0,
      stringToDecimal(fields?.endingGas97.value) || 0
    );
  };

  const setDeliveryVariance = () => {
    let deliveries = shift?.delivery.deliveryProducts;
    let dieselTotalVariance = 0;
    let gas91TotalVariance = 0;
    let gas95TotalVariance = 0;
    let gas97TotalVariance = 0;

    filter(deliveries, (e) => {
      const diesel = e.product === ProductCodeEnum.DIESEL;
      if (diesel) {
        dieselTotalVariance = dieselTotalVariance + computeDeliveryVariance(e);
      }
      return diesel;
    });

    filter(deliveries, (e) => {
      const gas91 = e.product === ProductCodeEnum.GAS91;
      if (gas91) {
        gas91TotalVariance = gas91TotalVariance + computeDeliveryVariance(e);
      }
      return gas91;
    });

    filter(deliveries, (e) => {
      const gas95 = e.product === ProductCodeEnum.GAS95;
      if (gas95) {
        gas95TotalVariance = gas95TotalVariance + computeDeliveryVariance(e);
      }
      return gas95;
    });

    filter(deliveries, (e) => {
      const gas97 = e.product === ProductCodeEnum.GAS97;
      if (gas97) {
        gas97TotalVariance = gas97TotalVariance + computeDeliveryVariance(e);
      }
      return gas97;
    });

    diesel.deliveryVariance = dieselTotalVariance;
    gas91.deliveryVariance = gas91TotalVariance;
    gas95.deliveryVariance = gas95TotalVariance;
    gas97.deliveryVariance = gas97TotalVariance;
  };

  const setTotalVariance = () => {
    if (!isShiftCompleted) {
      diesel.totalVariance = "";
      gas91.totalVariance = "";
      gas95.totalVariance = "";
      gas97.totalVariance = "";
      return;
    }

    if (pageMode === FormMode.Edit && fields?.isReadingDipstickEnding.value) {
      return;
    }

    if (pageMode === FormMode.View && !shift?.shift.isReadingDipstickEnding) {
      return;
    }

    if (!fields?.isReadingDipstickBeginning.value || !fields?.isReadingDipstickEnding.value) {
      diesel.totalVariance = Number(
        Big(diesel?.variance || 0).minus(diesel?.deliveryVariance || 0)
      );
      gas91.totalVariance = Number(Big(gas91?.variance || 0).minus(gas91?.deliveryVariance || 0));
      gas95.totalVariance = Number(Big(gas95?.variance || 0).minus(gas95?.deliveryVariance || 0));
      gas97.totalVariance = Number(Big(gas97?.variance || 0).minus(gas97?.deliveryVariance || 0));
    }
  };

  const setTotalizer = () => {
    const dieselTotalizer =
      sumBy(shift?.transactionItem.dieselTransactionItem, "endingTotalizer") || 0;
    const gas91Totalizer =
      sumBy(shift?.transactionItem.gas91TransactionItem, "endingTotalizer") || 0;
    const gas95Totalizer =
      sumBy(shift?.transactionItem.gas95TransactionItem, "endingTotalizer") || 0;
    const gas97Totalizer =
      sumBy(shift?.transactionItem.gas97TransactionItem, "endingTotalizer") || 0;

    diesel.endingInventoryPerTotalizer = dieselTotalizer;
    gas91.endingInventoryPerTotalizer = gas91Totalizer;
    gas95.endingInventoryPerTotalizer = gas95Totalizer;
    gas97.endingInventoryPerTotalizer = gas97Totalizer;

    if (!isShiftCompleted) {
      diesel.variance = "";
      gas91.variance = "";
      gas95.variance = "";
      gas97.variance = "";
      return;
    }

    if (pageMode === FormMode.Edit && fields?.isReadingDipstickEnding.value) {
      return;
    }

    if (pageMode === FormMode.View && !shift?.shift.isReadingDipstickEnding) {
      return;
    }

    diesel.variance = Number(
      Big(stringToDecimal(fields?.endingDiesel.value) || 0).minus(dieselTotalizer)
    );
    gas91.variance = Number(
      Big(stringToDecimal(fields?.endingGas91.value) || 0).minus(gas91Totalizer)
    );
    gas95.variance = Number(
      Big(stringToDecimal(fields?.endingGas95.value) || 0).minus(gas95Totalizer)
    );
    gas97.variance = Number(
      Big(stringToDecimal(fields?.endingGas97.value) || 0).minus(gas97Totalizer)
    );
  };

  const setList = () => {
    let list = [];
    setDelivery();
    setLitersSoldPerTotalizer();
    setBeginningDipstick();
    setEndingInventoryPerDipstick();
    setLitersAvailableForSale();

    setDeliveryVariance();
    setTotalizer();
    setTotalVariance();

    list.push(
      {
        ugt: "Beginning Dipstick",
        diesel: diesel.beginningDipstick,
        gas91: gas91.beginningDipstick,
        gas95: gas95.beginningDipstick,
        gas97: gas97.beginningDipstick,
      },
      {
        ugt: "Delivery",
        diesel: formatVolume(diesel.delivery),
        gas91: formatVolume(gas91.delivery),
        gas95: formatVolume(gas95.delivery),
        gas97: formatVolume(gas97.delivery),
      },
      {
        ugt: "Liters Available for Sale",
        diesel: formatValues(diesel.litersAvailableForSale),
        gas91: formatValues(gas91.litersAvailableForSale),
        gas95: formatValues(gas95.litersAvailableForSale),
        gas97: formatValues(gas97.litersAvailableForSale),
      },
      {
        ugt: "Liters Sold per Totalizer",
        diesel: formatVolume(diesel.litersSoldPerTotalizer),
        gas91: formatVolume(gas91.litersSoldPerTotalizer),
        gas95: formatVolume(gas95.litersSoldPerTotalizer),
        gas97: formatVolume(gas97.litersSoldPerTotalizer),
      },
      {
        ugt: "Calibration",
        diesel: diesel.calibration,
        gas91: gas91.calibration,
        gas95: gas95.calibration,
        gas97: gas97.calibration,
      },
      {
        ugt: "Returns",
        diesel: diesel.returns,
        gas91: gas91.returns,
        gas95: gas95.returns,
        gas97: gas97.returns,
      },
      {
        ugt: "Pump Error",
        diesel: diesel.pumpError,
        gas91: gas91.pumpError,
        gas95: gas95.pumpError,
        gas97: gas97.pumpError,
      },
      {
        ugt: "Ending Inventory per Totalizer",
        diesel: formatValues(diesel.endingInventoryPerTotalizer),
        gas91: formatValues(gas91.endingInventoryPerTotalizer),
        gas95: formatValues(gas95.endingInventoryPerTotalizer),
        gas97: formatValues(gas97.endingInventoryPerTotalizer),
      },
      {
        ugt: "Ending Inventory per Dipstick",
        diesel: diesel.endingInventoryPerDipstick,
        gas91: gas91.endingInventoryPerDipstick,
        gas95: gas95.endingInventoryPerDipstick,
        gas97: gas97.endingInventoryPerDipstick,
      },
      {
        ugt: "Variance",
        diesel: formatValues(diesel.variance),
        gas91: formatValues(gas91.variance),
        gas95: formatValues(gas95.variance),
        gas97: formatValues(gas97.variance),
        dieselStyle: [
          styles.content,
          diesel.variance > 0 && styles.positive,
          diesel.variance < 0 && styles.negative,
        ],
        gas91Style: [
          styles.content,
          gas91.variance > 0 && styles.positive,
          gas91.variance < 0 && styles.negative,
        ],
        gas95Style: [
          styles.content,
          gas95.variance > 0 && styles.positive,
          gas95.variance < 0 && styles.negative,
        ],
        gas97Style: [
          styles.content,
          gas97.variance > 0 && styles.positive,
          gas97.variance < 0 && styles.negative,
        ],
      },
      {
        ugt: "Delivery Variance",
        diesel: formatVolume(diesel.deliveryVariance),
        gas91: formatVolume(gas91.deliveryVariance),
        gas95: formatVolume(gas95.deliveryVariance),
        gas97: formatVolume(gas97.deliveryVariance),
      },
      {
        ugt: "Total Variance",
        diesel: formatValues(diesel.totalVariance),
        gas91: formatValues(gas91.totalVariance),
        gas95: formatValues(gas95.totalVariance),
        gas97: formatValues(gas97.totalVariance),
      }
    );

    return list;
  };

  const { table } = useDataTable({
    value: setList(),
    filter: { initialState: initialFilterState(), mapper: (val) => val },
    table: {
      key: "shift",
      name: "fuelVariance",
      mapper: (val) => fuelVarianceMapper({ val }),
      columns: fuelVarianceColumn,
    },
  });

  return (
    <>
      <Title small black>
        {locale.fuelVariance}
      </Title>
      <div className={styles.spacer10} />
      <div className={styles.tableContainer}>
        <DataTableV2 {...table} hideNoSearchResultRender tableVersion="v2" hidePagination />
      </div>
      <div className={styles.spacer50} />
    </>
  );
};

export default ShiftDetailsFuelVariance;
