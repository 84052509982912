import locale from "localization";
import styles from "./shift-records-details-cash-and-non-cash.module.scss";
import { Text, Title } from "components/commons";
import { cashOnHandColumn } from "./shift-records-details-cash-non-cash-column";
import { formatAmount } from "utils";
import Big from "big.js";

const ShiftDetailsCashOnHand = ({ safeDrop, setSafeDrop, pageMode }) => {
  // const onDenominationChange = (i, type) => {
  //   const newSafeDrop = [...safeDrop];
  //   if (type === "inc") {
  //     const value = Number(Big(newSafeDrop[i].safeDrop).add(1));
  //     newSafeDrop[i].safeDrop = value;
  //     newSafeDrop[i].total = Number(Big(newSafeDrop[i].safeDropItem).add(value));
  //     newSafeDrop[i].totalAmount = Number(
  //       Big(newSafeDrop[i].denomination).mul(newSafeDrop[i].total)
  //     );
  //   } else {
  //     const value = Number(Big(newSafeDrop[i].safeDrop).minus(1));

  //     if (Big(value).lt(0)) {
  //       return;
  //     }

  //     newSafeDrop[i].safeDrop = value;
  //     newSafeDrop[i].total = Number(Big(value).add(newSafeDrop[i].safeDropItem));
  //     newSafeDrop[i].totalAmount = Number(
  //       Big(newSafeDrop[i].denomination).mul(newSafeDrop[i].total)
  //     );
  //   }

  //   setSafeDrop(newSafeDrop);
  // };
  // const onCoinAmountChange = (i, value) => {
  //   const newSafeDrop = [...safeDrop];
  //   let parseValue = parseNumber(value);

  //   parseValue = isNaN(parseValue) || value === "" ? 0 : parseValue;
  //   if (Big(parseValue).gt(500)) {
  //     return;
  //   }
  //   newSafeDrop[i].safeDrop = value;
  //   newSafeDrop[i].total = Number(Big(parseValue).add(newSafeDrop[i].safeDropItem));
  //   newSafeDrop[i].totalAmount = Number(Big(newSafeDrop[i].denomination).mul(newSafeDrop[i].total));

  //   setSafeDrop(newSafeDrop);
  // };

  return (
    <>
      <Title small black>
        {locale.cashOnHand}
      </Title>
      <div className={styles.spacer10} />
      <div className={styles.tableContainer}>
        {safeDrop && safeDrop.length > 0 && (
          <div style={{ padding: "20px" }}>
            <table className={styles.table}>
              <thead>
                <tr>
                  {cashOnHandColumn.map((item, i) => {
                    return <th key={i}>{item.text}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {safeDrop.map((item, i) => {
                  let denomination = formatAmount(item.denomination);
                  if (item.key === "twenty") {
                    denomination = `${formatAmount(item.denomination)} bills`;
                  } else if (item.key === "twentyCoins") {
                    denomination = `${formatAmount(item.denomination)} coins`;
                  } else if (item.key === "coinAmount") {
                    denomination = locale.coins;
                  }
                  return (
                    <tr key={i}>
                      <td>{denomination}</td>
                      <td>
                        <Text className={styles.content}>{item.safeDropItem}</Text>
                      </td>
                      <td>
                        <Text className={styles.content}>{item.safeDrop}</Text>
                      </td>
                      <td>
                        <Text className={styles.content}>{Number(Big(item.total))}</Text>
                      </td>
                      <td>
                        <Text className={styles.content}>{formatAmount(item.totalAmount)}</Text>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div className={styles.spacer50} />
    </>
  );
};

export default ShiftDetailsCashOnHand;
