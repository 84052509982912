import locale from "localization/en";
import "./sales-summary-column.scss";
import Text from "../../../components/commons/text/text.js";

export const salesSummaryColumns = [
  { key: "date", text: locale.date, width: "130px", className: "alignToTop" },
  {
    key: "accumulatingInvoiceBeginning",
    text: <Text className={"customHeaderText"}>{locale.beginningAccumulatedSalesInvoice}</Text>,
    className: "alignToTop",
    width: "180px",
  },
  {
    key: "accumulatingInvoiceEnding",
    text: <Text className={"customHeaderText"}>{locale.endingAccumulatedSalesInvoice}</Text>,
    className: "alignToTop",
    width: "180px",
  },
  {
    key: "count",
    text: <Text className={"customHeaderText"}>{locale.salesCount}</Text>,
    className: "alignToTop",
  },
  {
    key: "accumulatingSalesBeginning",
    text: <Text className={"customHeaderText"}>{locale.beginningAccumulatedGrossSales}</Text>,
    className: "alignToTop",
    width: "170px",
  },
  {
    key: "accumulatingSalesEnding",
    text: <Text className={"customHeaderText"}>{locale.endingAccumulatedGrossSales}</Text>,
    className: "accumulatingSalesHeader",
    width: "150px",
  },
  {
    key: "total",
    text: locale.grossSales,
    width: "140px",
    className: "alignToTop",
  },
  { key: "counter", text: locale.zReadCount, className: "alignToTop" },
  { key: "vatableSales", text: locale.vatableSales, width: "120px", className: "alignToTop" },
  { key: "vatAmount", text: locale.vatAmount, width: "120px", className: "alignToTop" },
  { key: "vatExempt", text: locale.vatExempt, width: "120px", className: "alignToTop" },
  { key: "zeroRatedSales", text: locale.zeroRatedSales, width: "120px", className: "alignToTop" },
  { key: "salesDiscount", text: locale.salesDiscount, width: "120px", className: "alignToTop" },
  { key: "salesPremium", text: locale.salesPremium, width: "120px", className: "alignToTop" },
  { key: "discount", text: locale.regularDiscount, width: "120px", className: "alignToTop" },
  { key: "seniorDiscount", text: locale.seniorDiscount, width: "120px", className: "alignToTop" },
  { key: "pwdDiscount", text: locale.pwdDiscount, width: "120px", className: "alignToTop" },
  { key: "netTotal", text: locale.netTotal, width: "120px", className: "alignToTop" },
];
