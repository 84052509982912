const ApiPath = {
  Login: "ms-profile/user/login",
  Logout: "ms-profile/user/logout",
  Refresh: "ms-profile/user/refresh",
  GetUser: "ms-profile/user",
  GetUsers: "ms-profile/user/users",
  GenerateReport: "ms-pos/report",
  ShiftRecords: "ms-pos/shift",
  GetStations: "ms-price-capture/station",
  Station: "ms-station/station",
  DailyRecords: "ms-pos/daily-record",
  Transaction: "ms-pos/transaction",
};

export default ApiPath;
