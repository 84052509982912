import React, { useEffect } from "react";
import styles from "./shift-records-details-info.module.scss";
import { Button, Dropdown, Field, Image, Loader, Text, TextField } from "components/commons";
import LabelAndValueRow from "components/commons/label-and-value/label-and-value-row";
import { locale } from "localization/en";
import { dateFormat, timeFormat } from "utils";
import { EmployeePosition, FormMode, ShiftStatus } from "enums";
import { DeleteIcon } from "images";
import { prettifyEmployeePosition } from "utils/pretty.utils";
import Validation from "utils/validation.utils";
import grid from "styles/grid.module.scss";
import AddIcon from "@material-ui/icons/Add";

const ShiftRecordsDetailsInfoModule = ({
  loadingShift,
  station,
  shift,
  pageMode,
  employees,
  setEmployees,
  form,
}) => {
  const { fields, modifyField } = form;

  const addRow = () => {
    setEmployees([
      ...employees,
      {
        shiftEmployeeId: null,
        name: "",
        agency: "",
        label: "",
        nameCheck: true,
        agencyCheck: true,
        labelCheck: true,
      },
    ]);
  };

  const onRemove = (i) => {
    const newForm = [...employees];
    newForm.splice(i, 1);
    setEmployees(newForm);
  };

  const onChangeName = (value, i) => {
    const newForm = [...employees];
    let inValid = true;
    newForm[i]["name"] = value;

    if (value.length > 0) {
      inValid = false;
    }
    newForm[i].nameCheck = inValid;

    setEmployees(newForm);
  };

  const onChangeAgency = (value, i) => {
    const newForm = [...employees];
    let inValid = true;
    newForm[i]["agency"] = value;
    if (value.length > 0) {
      inValid = false;
    }
    newForm[i].agencyCheck = inValid;

    setEmployees(newForm);
  };

  const onChangeLabel = (value, i) => {
    const newForm = [...employees];
    let inValid = true;
    newForm[i]["label"] = value;
    if (value.length > 0) {
      inValid = false;
    }
    newForm[i].labelCheck = inValid;

    setEmployees(newForm);
  };

  useEffect(() => {
    if (pageMode === FormMode.Edit) {
      if (employees.length > 0 && employees[0].agency === "Employee") {
        onChangeAgency("", 0); //overwrite employee placeholder
      }

      if (employees.length === 0) {
        addRow(); // add default row if cashier is empty
      }
    }
  });

  return (
    <>
      <Loader open={loadingShift} />
      {shift && !loadingShift && (
        <div>
          <div className={styles.container}>
            <div>
              <div>
                <Text className={styles.header}>{station?.name}</Text>
              </div>
              <div style={{ width: "100%" }}>
                <LabelAndValueRow
                  label={locale.shiftDate}
                  desc={dateFormat(shift?.shift.startShift)}
                />
                <LabelAndValueRow
                  label={locale.shiftTime}
                  desc={`${dateFormat(shift?.shift.startShift)} ${timeFormat(
                    shift?.shift.startShift
                  )} - ${dateFormat(shift?.shift.endShift)} ${timeFormat(shift?.shift.endShift)}`}
                />
                {pageMode === FormMode.Edit ? (
                  <Field className={styles.fields} {...fields?.shiftNumber} horizontal>
                    <TextField
                      upperCase
                      {...fields?.shiftNumber}
                      onChange={(name, value) => {
                        modifyField(name, value);

                        if (
                          ![
                            "1",
                            "1A",
                            "1B",
                            "1C",
                            "1D",
                            "2",
                            "2A",
                            "2B",
                            "2C",
                            "2D",
                            "3",
                            "3A",
                            "3B",
                            "3C",
                            "3D",
                            "",
                          ].includes(value.value)
                        ) {
                          modifyField(name, "");
                        }
                      }}
                    />
                  </Field>
                ) : (
                  <LabelAndValueRow
                    label={fields?.shiftNumber.label}
                    desc={
                      shift?.shift.status === ShiftStatus.Started
                        ? locale.currentShift
                        : shift?.shift.shiftNumber
                    }
                  />
                )}
              </div>
              <div>
                <Text className={styles.header}>{locale.shiftAttendance}</Text>
              </div>
              {pageMode === FormMode.Edit ? (
                <table className={styles.tableView}>
                  <tbody>
                    {employees.map((item, i) => (
                      <tr key={i}>
                        <td className={styles.addOrEditTd}>
                          <Field
                            className={styles.fieldLabel}
                            label={i > 0 ? locale.employeeOnDuty : locale.cashier}
                          >
                            <TextField
                              error={item.nameCheck}
                              label={locale.name}
                              value={item.name}
                              validations={[Validation.required({ noMessage: true })]}
                              onChange={(_, { value }) => {
                                onChangeName(value, i);
                              }}
                            />
                          </Field>
                        </td>
                        <td className={styles.addOrEditTd}>
                          <Field className={styles.fieldLabel} label={locale.agency}>
                            <TextField
                              error={item.agencyCheck}
                              label={locale.agency}
                              value={item.agency === "Employee" ? null : item.agency}
                              validations={[Validation.required({ noMessage: true })]}
                              onChange={(_, { value }) => {
                                onChangeAgency(value, i);
                                if (i === 0) {
                                  onChangeLabel(
                                    prettifyEmployeePosition(EmployeePosition.CASHIER),
                                    i
                                  );
                                }
                              }}
                            />
                          </Field>
                        </td>
                        {i > 0 && (
                          <td>
                            <Field className={styles.fieldLabel} label={locale.employeePosition}>
                              <Dropdown
                                error={item.labelCheck}
                                onChange={(value) => {
                                  onChangeLabel(value, i);
                                }}
                                value={item.label}
                                options={[
                                  {
                                    label: prettifyEmployeePosition(EmployeePosition.STATION_HEAD),
                                    value: prettifyEmployeePosition(EmployeePosition.STATION_HEAD),
                                  },
                                  {
                                    label: prettifyEmployeePosition(EmployeePosition.STATION_CREW),
                                    value: prettifyEmployeePosition(EmployeePosition.STATION_CREW),
                                  },
                                  {
                                    label: prettifyEmployeePosition(EmployeePosition.CASHIER),
                                    value: prettifyEmployeePosition(EmployeePosition.CASHIER),
                                  },
                                  {
                                    label: prettifyEmployeePosition(
                                      EmployeePosition.SECURITY_GUARD
                                    ),
                                    value: prettifyEmployeePosition(
                                      EmployeePosition.SECURITY_GUARD
                                    ),
                                  },
                                  {
                                    label: prettifyEmployeePosition(
                                      EmployeePosition.SERVICE_ADVISOR
                                    ),
                                    value: prettifyEmployeePosition(
                                      EmployeePosition.SERVICE_ADVISOR
                                    ),
                                  },
                                  {
                                    label: prettifyEmployeePosition(EmployeePosition.TECHNICIAN),
                                    value: prettifyEmployeePosition(EmployeePosition.TECHNICIAN),
                                  },
                                ]}
                              />
                            </Field>
                          </td>
                        )}
                        <td>
                          <div>
                            {i === 0 ? (
                              ""
                            ) : (
                              <span onClick={() => onRemove(i)} className={styles.remove}>
                                <Image src={DeleteIcon} />
                              </span>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <>
                  {employees.length > 0 && (
                    <>
                      <div className={grid.row}>
                        <div className={grid.column}>
                          <Text className={styles.classLabel}>{locale.cashier}</Text>
                        </div>

                        <div className={grid.column}>
                          <Text className={styles.classLabel}>{locale.agency}</Text>
                        </div>
                      </div>

                      <>
                        <div className={grid.row}>
                          <div className={grid.column}>
                            <Text className={styles.content}>{employees[0].name}</Text>
                          </div>

                          <div className={grid.column}>
                            <Text className={styles.content}>{`${
                              employees[0].agency === "Employee" ? "-" : employees[0].agency
                            }`}</Text>
                          </div>
                        </div>
                      </>
                    </>
                  )}

                  {employees.length > 1 && (
                    <>
                      <div className={grid.row}>
                        <div className={grid.column}>
                          <Text className={styles.classLabel}>{locale.crewOnDuty}</Text>
                        </div>

                        <div className={grid.column}>
                          <Text className={styles.classLabel}>{locale.agency}</Text>
                        </div>
                      </div>
                    </>
                  )}
                  {employees.map(
                    (item, i) =>
                      i > 0 && (
                        <>
                          <div className={grid.row}>
                            <div className={grid.column}>
                              <Text className={styles.content}>{item.name}</Text>
                            </div>

                            <div className={grid.column}>
                              <Text className={styles.content}>{`${
                                item.agency === "Employee" ? "-" : item.agency
                              }`}</Text>
                            </div>
                          </div>
                        </>
                      )
                  )}
                </>
              )}
            </div>
            {pageMode === FormMode.Edit && (
              <Button
                exportButton
                onClick={addRow}
                startIcon={<AddIcon />}
                disabled={employees.find((i) => i.nameCheck || i.agencyCheck | i.labelCheck)}
              >
                {locale.addEmployee}
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ShiftRecordsDetailsInfoModule;
