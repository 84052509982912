import { Text, Pill, PopOverMenu } from "components/commons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { dateFormat, formatAmount, timeFormat } from "utils";
import styles from "./shift-records.module.scss";
import { locale } from "localization/en";
import { FormMode, Path, ShiftStatus } from "enums";
import { prettifyShiftStatus } from "utils/pretty.utils";
import classNames from "classnames";

export const mapDataToList = ({ shifts, history }) => {
  const {
    startShift,
    endShift,
    shiftNumber,
    status,
    employees,
    transactions,
    shiftId,
    totalTransactionCount,
    totalFuelSales,
  } = shifts;

  const transactionCount = transactions && transactions.length > 0 ? transactions.length : 0;
  let syncPercentage = calculateSyncProgress({
    totalBosTransactionCount: transactions?.length,
    totalTransactionCount,
  });

  let syncStatus = "pending";
  if (syncPercentage === 100) {
    syncStatus = "done";
  } else if (syncPercentage > 100 && syncPercentage < 0) {
    syncStatus = "ongoing";
  }

  let cashier = "--";
  if (employees && employees.length > 0) {
    const copyEmployee = [...employees];
    copyEmployee.sort((a, b) => {
      return b.shiftEmployeeId - a.shiftEmployeeId;
    });
    cashier = copyEmployee[employees.length - 1].name;
  }

  const options = [
    {
      content: locale.viewDetails,
      onClick: () => {
        history.push(Path.ViewShiftDetailsInfoById(shiftId, FormMode.View));
      },
    },
  ];
  if (status === ShiftStatus.Ended && syncPercentage === 100) {
    options.push({
      content: locale.editDetails,
      onClick: () => {
        history.push(Path.ViewShiftDetailsInfoById(shiftId, FormMode.Edit));
      },
    });
  }

  return {
    startShift: (
      <>
        <Text className={styles.td}>{dateFormat(startShift ?? "")}</Text>
        <Text className={styles.td}>{timeFormat(startShift ?? "")}</Text>
      </>
    ),
    endShift: (
      <>
        <Text className={styles.td}>{endShift != null ? dateFormat(endShift) : "--"}</Text>
        <Text className={styles.td}>{endShift != null ? timeFormat(endShift) : ""}</Text>
      </>
    ),
    shiftNumber: (
      <>
        <Text className={styles.td}>
          {status === ShiftStatus.Started ? locale.currentShift : shiftNumber ?? "--"}
        </Text>
      </>
    ),
    cashier: (
      <>
        <Text className={styles.td}>{cashier}</Text>
      </>
    ),
    totalFuelSales: (
      <>
        <Text className={styles.td}>{formatAmount(totalFuelSales ?? 0)}</Text>
      </>
    ),
    shiftStatus: (
      <Pill
        className={styles.status}
        cheddar={status === ShiftStatus.Ended}
        grass={status === ShiftStatus.Started}
        cement={status === ShiftStatus.Closed}
      >
        {prettifyShiftStatus(status)}
      </Pill>
    ),
    syncProgress: (
      <div className={styles.sync}>
        <Text className={classNames(styles.percentage, styles[syncStatus])}>
          {status !== ShiftStatus.Started && `${syncPercentage} %`}
          {status === ShiftStatus.Started ? (
            <span className={styles.content}>{`(${transactionCount})`}</span>
          ) : (
            <span
              className={styles.content}
            >{`(${transactionCount}/${totalTransactionCount})`}</span>
          )}
        </Text>
      </div>
    ),
    actions: (
      <PopOverMenu options={options}>
        <MoreVertIcon className={styles.icon} />
      </PopOverMenu>
    ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { statuses, cashiers, ...fs } = filterState;
  let newCashiers;
  if (cashiers.value.length && !cashiers.isAll) {
    newCashiers = cashiers.value
      .map(({ value }) => {
        return value;
      })
      .join(",");
  } else {
    newCashiers = null;
  }

  return {
    ...fs,
    cashiers: newCashiers,
    statuses: !statuses.isSelectedAll ? statuses.value.join(",") : null,
  };
};

export const calculateSyncProgress = ({
  totalTransactionCount = 0,
  totalBosTransactionCount = 0,
}) => {
  if (Number(totalTransactionCount) === 0 && Number(totalBosTransactionCount) === 0) {
    return 100;
  }

  const syncPercentage =
    totalTransactionCount > 0 ? (totalBosTransactionCount / totalTransactionCount) * 100 : 0;

  return Math.round(syncPercentage * 100) / 100;
};
