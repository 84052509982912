import React from "react";
import { Route, Switch } from "react-router-dom";
import { Path } from "enums";
import ShiftRecordsListPage from "./shift-records-list.page";
import ShiftRecordsDetailsModule from "modules/shift-records/shift-records-details/shift-records-details.module";

const ShiftRecordsPage = () => {
  return (
    <Switch>
      <Route exact path={Path.ShiftRecords} component={ShiftRecordsListPage} />
      <Route exact path={Path.ViewShiftDetails} component={ShiftRecordsDetailsModule} />
      {/* <Redirect to={Path.ShiftRecords} /> */}
    </Switch>
  );
};

export default ShiftRecordsPage;
