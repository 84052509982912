import locale from "localization";
import styles from "./shift-records-details-cash-and-non-cash.module.scss";
import { Title } from "components/commons";
import { cashTransactionMapper } from "././shift-records-details-cash-non-cash.mapper";
import { cashTransactionColumn } from "./shift-records-details-cash-non-cash-column";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import { initialFilterState } from "modules/shift-records/shift-records-filter.state";
import useDataTable from "hooks/useDataTable";

const ShiftDetailsCash = ({ shift }) => {
  const { table } = useDataTable({
    value: shift?.transactionPaymentMethod.cash,
    filter: { initialState: initialFilterState(), mapper: (data) => data},
    table: {
      key: "cash",
      name: "cashTransactions",
      mapper: (val) => cashTransactionMapper({ val }),
      columns: cashTransactionColumn,
    },
  });

  return (
    <>
      <Title small black>
        {locale.cashTransactions}
      </Title>
      <div className={styles.spacer10} />
      <div className={styles.tableContainer}>
        <DataTableV2 
          {...table} 
          hideNoSearchResultRender
          tableVersion="v2"
          hidePagination
        />
      </div>
      <div className={styles.spacer50} />
    </>
  );
};

export default ShiftDetailsCash;