import locale from "localization";
import styles from "./shift-records-details-sales-inventory.module.scss";
import { prettifyProductCode } from "utils/pretty.utils";
import ProductCode from "enums/product-code";
import ShiftDetailsDipstick from "./shift-details-sales-inventory-dipstick";
import { Title } from "components/commons";
import FuelSummary from "./shift-details-fuel-product-summary";
import { sortBy } from "lodash";

const ShiftDetailsFuelSales = ({
  shift,
  pageMode,
  form,
  beginDidNotPerformDipStick,
  setBeginDidNotPerformDipStick,
  endDidNotPerformDipStick,
  setEndDidNotPerformDipStick,
  progress,
  isShiftEndedCompletely,
}) => {
  const diesel = sortBy(shift?.transactionItem.dieselTransactionItem, "metadata");

  const gas91 = sortBy(shift?.transactionItem.gas91TransactionItem, "metadata");

  const gas95 = sortBy(shift?.transactionItem.gas95TransactionItem, "metadata");

  const gas97 = sortBy(shift?.transactionItem.gas97TransactionItem, "metadata");

  return (
    <>
      <Title small black>
        {locale.fuelSales}
      </Title>
      <ShiftDetailsDipstick
        shift={shift}
        progress={progress}
        pageMode={pageMode}
        form={form}
        beginDidNotPerformDipStick={beginDidNotPerformDipStick}
        setBeginDidNotPerformDipStick={setBeginDidNotPerformDipStick}
        endDidNotPerformDipStick={endDidNotPerformDipStick}
        setEndDidNotPerformDipStick={setEndDidNotPerformDipStick}
      />

      <div className={styles.tableContainer}>
        <div className={styles.fuelSalesTable}>
          <FuelSummary
            isShiftEndedCompletely={isShiftEndedCompletely}
            data={diesel}
            name={prettifyProductCode(ProductCode.DIESEL)}
          />
          <div className={styles.spacer20} />
          <FuelSummary
            isShiftEndedCompletely={isShiftEndedCompletely}
            data={gas91}
            name={prettifyProductCode(ProductCode.GAS91)}
          />
          <div className={styles.spacer20} />
          <FuelSummary
            isShiftEndedCompletely={isShiftEndedCompletely}
            data={gas95}
            name={prettifyProductCode(ProductCode.GAS95)}
          />
          <div className={styles.spacer20} />
          <FuelSummary
            isShiftEndedCompletely={isShiftEndedCompletely}
            data={gas97}
            name={prettifyProductCode(ProductCode.GAS97)}
          />
        </div>
      </div>
      <div className={styles.spacer10} />
    </>
  );
};

export default ShiftDetailsFuelSales;
