import React from "react";
import List from "components/commons/list/list";
import { NavLink } from "react-router-dom";
import styles from "./panel-button.module.scss";
import Button from "../button/button";

const PanelButtons = ({ items, className, isLink = true, page }) => {
  return (
    <List
      className={className}
      components={items.map((item) => {
        return isLink ? (
          <NavLink
            to={item.path}
            path={item.path}
            className={styles.navLink}
            activeClassName={styles.activeNavLink}
          >
            {item.text}
          </NavLink>
        ) : (
          <Button
            outline
            onClick={item.onClick}
            className={page !== item.page ? styles.navLink : styles.activeNavLink}
          >
            {item.text}
          </Button>
        );
      })}
    />
  );
};

export default PanelButtons;
