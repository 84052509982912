import { ApiPath } from "enums";
import { Get } from "utils";

export const getStations = async (query) => {
  const res = await Get(`${ApiPath.GetStations}`, { ...query });
  return res.data;
};

export const getStation = async ({ stationId, query }) => {
  const res = await Get(`${ApiPath.GetStations}/station-id/${stationId}`, { ...query });
  return res.data;
};

export const getStationById = async ({ stationId }) => {
  const res = await Get(`${ApiPath.Station}/${stationId}`);
  return res.data;
};
