import React from "react";
import { Loader } from "components/commons";
import styles from "./shift-records-details-cash-and-non-cash.module.scss";
import ShiftDetailsNonSales from "./shift-details-cash-non-cash-non-sales";
import ShiftDetailsNonCash from "./shift-details-cash-non-cash-non-cash";
import ShiftDetailsCash from "./shift-details-cash-non-cash-cash";
import ShiftDetailsExpense from "./shift-details-cash-non-cash-expense";
import ShiftDetailsCashOnHand from "./shift-details-cash-non-cash-cash-on-hand";
import ShiftDetailsNetCashSummary from "./shift-details-cash-non-cash-net-cash";
import ShiftDetailsBankDeposit from "./shift-details-cash-non-cash-bank-deposit";
import ShiftDetailsKeyAccount from "./shift-details-cash-non-cash-key-account";

const ShiftRecordsDetailsCashAndNonCashModule = (props) => {
  const {
    loadingShift,
    shift,
    safeDrop,
    setSafeDrop,
    employees,
    setEmployees,
    pageMode,
    employeesToPay,
    setEmployeesToPay,
    variance,
    setVariance,
  } = props;

  return (
    <>
      <Loader open={loadingShift} />
      {shift && !loadingShift && (
        <div className={styles.container}>
          <ShiftDetailsNonSales shift={shift} />
          <ShiftDetailsNonCash shift={shift} />
          <ShiftDetailsCash shift={shift} />
          <ShiftDetailsExpense shift={shift} />
          <ShiftDetailsCashOnHand
            shift={shift}
            safeDrop={safeDrop}
            setSafeDrop={setSafeDrop}
            pageMode={pageMode}
          />
          <ShiftDetailsNetCashSummary
            shift={shift}
            pageMode={pageMode}
            employees={employees}
            setEmployees={setEmployees}
            safeDrop={safeDrop}
            employeesToPay={employeesToPay}
            setEmployeesToPay={setEmployeesToPay}
            variance={variance}
            setVariance={setVariance}
          />
          <ShiftDetailsBankDeposit shift={shift} />
          <ShiftDetailsKeyAccount shift={shift} />
        </div>
      )}
    </>
  );
};

export default ShiftRecordsDetailsCashAndNonCashModule;
