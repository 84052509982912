import * as queryString from "query-string";
import React, { useState, useCallback } from "react";
import { useForm, useApi } from "hooks";
import { initialState } from "./login.state";
import styles from "./login.module.scss";
import { ErrorCode, Path, Portal, ProfileType } from "enums";
import {
  Field,
  TextField,
  PasswordField,
  Button,
  Highlight,
  Text,
  Image,
  Title,
  Footer,
} from "components/commons";
import { LoginCard, LoginBg1, LoginBg2 } from "images";
import locale from "localization";
import { handleRequest, redirectTo } from "utils";
import { isEmailValid } from "utils/text.utils";
import { login } from "apis";
import { getUserV2 } from "apis/profile.api";

const LoginModule = (props) => {
  const parse = queryString.parse(props.location ? props.location.search : null);
  const [errorCode] = useState(parse.code || null);
  const [incorrectCreds, setIncorrectCreds] = useState();

  const { request: loginRequest, loading: loggingIn } = useApi({
    api: login,
    handleOwnError: {
      badrequest: true,
    },
  });

  const { fields, modifyField, submitForm, makeFormInvalid, getFormValues, applyFieldErrors } =
    useForm({
      initialState,
    });

  const OnBlurValidateEmail = () => {
    if (fields.email.value && !isEmailValid(fields.email.value)) {
      applyFieldErrors({
        email: locale.pleaseEnterAValidEmailAddress,
      });
    }
  };

  const { request: getUserRequest, loading: fetchingUser } = useApi({
    api: getUserV2,
    pageError: true,
  });

  const fetchUser = useCallback(async () => {
    return await getUserRequest();
  }, [getUserRequest]);

  const handleLogin = async () => {
    setIncorrectCreds(false);
    const { email, password, remember } = getFormValues();
    const { profileType } = {
      profileType: ProfileType.BOS,
    };
    if (!email || !password) {
      applyFieldErrors({
        email: !email ? "" : null,
        password: !password ? "" : null,
      });
      return;
    }

    handleRequest(
      async () => {
        if (!isEmailValid(email)) {
          applyFieldErrors({
            email: locale.pleaseEnterAValidEmailAddress,
          });
          return;
        }
        const res = await loginRequest({ email, password, profileType }, () => handleLogin());
        localStorage.setItem("accessToken", res?.AccessToken);
        localStorage.setItem("refreshToken", res.RefreshToken);

        if (res) {
          const userInfo = await fetchUser();
          if (userInfo) {
            const { role, stationId } = userInfo?.userAccesses?.find((element) => {
              return element.portal === Portal.BOS;
            });
            localStorage.setItem("role", role);
            localStorage.setItem("stationId", stationId);

            if (remember) {
              localStorage.setItem("email", email);
            } else {
              localStorage.removeItem("email");
            }
            redirectTo(Path.Slash);
          } else {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            setIncorrectCreds(true);
          }
        } else {
          setIncorrectCreds(true);
        }
      },
      null,
      (err) => {
        if (err.data?.errorCode === ErrorCode.IncorrectCredentials) {
          makeFormInvalid();
          setIncorrectCreds(true);
          return applyFieldErrors({
            email: "",
            password: "",
          });
        }
        if (err.data?.errorCode === ErrorCode.IncorrectProfileType) {
          makeFormInvalid();
          setIncorrectCreds(true);
          return applyFieldErrors({
            email: "",
            password: "",
          });
        }
        return err.showError();
      }
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Image src={LoginBg1} className={styles.loginEmbelishment1} />
        <Image src={LoginBg2} className={styles.loginEmbelishment2} />
        <div className={styles.contentContainer}>
          <div className={styles.image}>
            <Image src={LoginCard} className={styles.contentImage} />
          </div>

          <div className={styles.login}>
            <Title black={true} className={styles.title}>
              {locale.bos}
            </Title>
            {!incorrectCreds && errorCode && errorCode === ErrorCode.InvalidInviteFleetToken && (
              <Highlight warning>
                <Text>{locale.expiredActivationLinkToken}</Text>
              </Highlight>
            )}
            {incorrectCreds && (
              <Highlight error>
                <Text>{locale.incorrectEmailOrPassword}</Text>
              </Highlight>
            )}
            {incorrectCreds && errorCode === ErrorCode.IncorrectProfileType && (
              <Highlight error>
                <Text>{locale.incorrectEmailOrPassword}</Text>
              </Highlight>
            )}
            <Field {...fields.email}>
              <TextField {...fields.email} onChange={modifyField} onBlur={OnBlurValidateEmail} />
            </Field>
            <Field {...fields.password}>
              <PasswordField
                {...fields.password}
                onChange={modifyField}
                onEnter={() => {
                  submitForm(handleLogin);
                }}
              />
            </Field>
            {/* <div className={styles.remember}>
              <Checkbox {...fields.remember} onChange={modifyField} />
              {fields.remember.label}
            </div> */}
            <Button
              primary
              className={styles.loginButton}
              loading={loggingIn || fetchingUser}
              onClick={() => {
                submitForm(handleLogin);
              }}
            >
              {locale.logIn}
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LoginModule;
