import React, { useCallback, useMemo, useState, useContext, useEffect } from "react";
import { BackLink, Intro, Loader } from "components/commons";
import { locale } from "localization/en";
import { FormMode, Path, ShiftStatus } from "enums";
import PanelButtons from "components/commons/panel-button/panel-buttons";
import { useApi, useForm, useMount, useRouter, useModal } from "hooks";
import { getShiftByShiftId, updateShiftById } from "apis/shift-records.api";
import ShiftRecordsDetailsInfoModule from "./shift-records-details-info/shift-records-details-info.module";
import ShiftRecordsDetailsCashAndNonCashModule from "./shift-records-details-sales-cash-non-cash/shift-records-details-cash-and-non-cash.module";
import ShiftRecordsDetailsSalesInventoryModule from "./shift-records-details-sales-inventory/shift-records-details-sales-inventory.module";
import { initialState as formState } from "./shift-records-details.state";
import Big from "big.js";
import { SuccessModal } from "components/modals";
import { StocqClockIn } from "images";
import { useHistory } from "react-router-dom";
import { StationContext } from "contexts";
import { sortBy } from "lodash";
import { every } from "lodash";
import { parseNumber } from "utils";
import { calculateSyncProgress } from "../shift-records.mapper";
import { sumBy } from "lodash";
import { stringToDecimal } from "utils/text.utils";

const ShiftRecordsDetailsModule = () => {
  const { query } = useRouter();
  const successModal = useModal();
  const history = useHistory();
  const shiftId = query?.id;
  const pageMode = query?.pageMode;
  const { station } = useContext(StationContext);

  const [page, setPage] = useState("info");

  const [employees, setEmployees] = useState([]);
  const [employeesToPay, setEmployeesToPay] = useState([]);
  const [variance, setVariance] = useState(0);

  const [beginDidNotPerformDipStick, setBeginDidNotPerformDipStick] = useState(false);
  const [endDidNotPerformDipStick, setEndDidNotPerformDipStick] = useState(false);

  const [safeDropId, setSafeDropId] = useState(null);
  const [safeDrop, setSafeDrop] = useState([]);

  useMount(async () => {
    if (shiftId) {
      const res = await getShiftRequest();

      let safeDropItem = {
        oneThousand: 0,
        fiveHundred: 0,
        twoHundred: 0,
        oneHundred: 0,
        fifty: 0,
        twenty: 0,
        twentyCoins: 0,
        ten: 0,
        five: 0,
        coinAmount: 0,
      };

      let safeDropEndShiftItem = {
        oneThousand: 0,
        fiveHundred: 0,
        twoHundred: 0,
        oneHundred: 0,
        fifty: 0,
        twenty: 0,
        twentyCoins: 0,
        ten: 0,
        five: 0,
        coinAmount: 0,
      };

      if (res) {
        if (res?.safeDrop) {
          safeDropItem = res.safeDrop;
        }

        if (res?.safeDropEndShift) {
          safeDropEndShiftItem = res.safeDropEndShift;
          setSafeDropId(safeDropEndShiftItem.safeDropId);
        }

        setBeginDidNotPerformDipStick(!res.shift.isReadingDipstickBeginning);
        setEndDidNotPerformDipStick(!res.shift.isReadingDipstickEnding);
      }

      let safeDropsList = [
        {
          key: "oneThousand",
          denomination: 1000,
          safeDropItem: safeDropItem.oneThousand,
          safeDrop: safeDropEndShiftItem.oneThousand || 0,
          total: Number(Big(safeDropItem.oneThousand).add(safeDropEndShiftItem.oneThousand)),
          totalAmount: Number(
            Big(safeDropItem.oneThousand).add(safeDropEndShiftItem.oneThousand).mul(1000)
          ),
        },
        {
          key: "fiveHundred",
          denomination: 500,
          safeDropItem: safeDropItem.fiveHundred,
          safeDrop: safeDropEndShiftItem.fiveHundred || 0,
          total: Number(Big(safeDropItem.fiveHundred).add(safeDropEndShiftItem.fiveHundred)),
          totalAmount: Number(
            Big(safeDropItem.fiveHundred).add(safeDropEndShiftItem.fiveHundred).mul(500)
          ),
        },
        {
          key: "twoHundred",
          denomination: 200,
          safeDropItem: safeDropItem.twoHundred,
          safeDrop: safeDropEndShiftItem.twoHundred || 0,
          total: Number(Big(safeDropItem.twoHundred).add(safeDropEndShiftItem.twoHundred)),
          totalAmount: Number(
            Big(safeDropItem.twoHundred).add(safeDropEndShiftItem.twoHundred).mul(200)
          ),
        },
        {
          key: "oneHundred",
          denomination: 100,
          safeDropItem: safeDropItem.oneHundred,
          safeDrop: safeDropEndShiftItem.oneHundred || 0,
          total: Number(Big(safeDropItem.oneHundred).add(safeDropEndShiftItem.oneHundred)),
          totalAmount: Number(
            Big(safeDropItem.oneHundred).add(safeDropEndShiftItem.oneHundred).mul(100)
          ),
        },
        {
          key: "fifty",
          denomination: 50,
          safeDropItem: safeDropItem.fifty,
          safeDrop: safeDropEndShiftItem.fifty || 0,
          total: Number(Big(safeDropItem.fifty).add(safeDropEndShiftItem.fifty)),
          totalAmount: Number(Big(safeDropItem.fifty).add(safeDropEndShiftItem.fifty).mul(50)),
        },
        {
          key: "twenty",
          denomination: 20,
          safeDropItem: safeDropItem.twenty,
          safeDrop: safeDropEndShiftItem.twenty || 0,
          total: Number(Big(safeDropItem.twenty).add(safeDropEndShiftItem.twenty)),
          totalAmount: Number(Big(safeDropItem.twenty).add(safeDropEndShiftItem.twenty).mul(20)),
        },
        {
          key: "twentyCoins",
          denomination: 20,
          safeDropItem: safeDropItem.twentyCoins,
          safeDrop: safeDropEndShiftItem.twentyCoins || 0,
          total: Number(Big(safeDropItem.twentyCoins).add(safeDropEndShiftItem.twentyCoins)),
          totalAmount: Number(
            Big(safeDropItem.twentyCoins).add(safeDropEndShiftItem.twentyCoins).mul(20)
          ),
        },
        {
          key: "ten",
          denomination: 10,
          safeDropItem: safeDropItem.ten,
          safeDrop: safeDropEndShiftItem.ten || 0,
          total: Number(Big(safeDropItem.ten).add(safeDropEndShiftItem.ten)),
          totalAmount: Number(Big(safeDropItem.ten).add(safeDropEndShiftItem.ten).mul(10)),
        },
        {
          key: "five",
          denomination: 5,
          safeDropItem: safeDropItem.five,
          safeDrop: safeDropEndShiftItem.five || 0,
          total: Number(Big(safeDropItem.five).add(safeDropEndShiftItem.five)),
          totalAmount: Number(Big(safeDropItem.five).add(safeDropEndShiftItem.five).mul(5)),
        },
        {
          key: "coinAmount",
          denomination: 1,
          safeDropItem: safeDropItem.coinAmount,
          safeDrop: safeDropEndShiftItem.coinAmount || 0,
          total: Number(Big(safeDropItem.coinAmount).add(safeDropEndShiftItem.coinAmount)),
          totalAmount: Number(
            Big(safeDropItem.coinAmount).add(safeDropEndShiftItem.coinAmount).mul(1)
          ),
        },
      ];

      if (res.shift.status === ShiftStatus.Started) {
        safeDropsList = safeDropsList.map((row) => ({
          ...row,
          safeDrop: 0, // End of Shift
          safeDropItem: row.safeDrop + row.safeDropItem,
        }));
      }

      setSafeDrop(safeDropsList);

      //set initial value of variance
      const { expense } = res.shift;
      const totalExpenses = sumBy(expense, "amount") || 0;
      const { beginningCashBalance, cashSales } = res.shift;
      const totalSafeDrop = sumBy(safeDrop, "totalAmount") || 0;
      const cashOnHand = Number(Big(beginningCashBalance).add(cashSales).minus(totalExpenses));
      setVariance(Number(Big(totalSafeDrop).minus(cashOnHand)));
    }
  });

  const {
    request: getShiftRequest,
    loading: loadingShift,
    result: getShiftResult,
  } = useApi({
    api: getShiftByShiftId,
    modalError: true,
    params: {
      shiftId,
    },
  });

  const updateShift = useApi({
    api: updateShiftById,
    handleOwnError: true,
    params: {
      shiftId,
    },
  });

  const items = [
    {
      text: locale.shiftDetails,
      page: "info",
      onClick: () => {
        setPage("info");
      },
    },
    {
      text: locale.salesAndInventory,
      page: "sales-inventory",
      onClick: () => {
        setPage("sales-inventory");
      },
    },
    {
      text: locale.cashAndNonCash,
      page: "cash-noncash",
      onClick: () => {
        setPage("cash-noncash");
      },
    },
  ];

  const transformData = useMemo(() => {
    const getShiftDetailFields = [
      "shiftNumber",
      "isReadingDipstickBeginning",
      "isReadingDipstickEnding",
    ];

    const getDipstickFields = [
      "beginningDiesel",
      "beginningGas91",
      "beginningGas95",
      "beginningGas97",
      "beginningDieselCm",
      "beginningGas91Cm",
      "beginningGas95Cm",
      "beginningGas97Cm",
      "endingDiesel",
      "endingGas91",
      "endingGas95",
      "endingGas97",
      "endingDieselCm",
      "endingGas91Cm",
      "endingGas95Cm",
      "endingGas97Cm",
    ];

    const state = {};

    if (getShiftResult) {
      const isReadingDipstickBeginning = getShiftResult?.shift.isReadingDipstickBeginning;
      const isReadingDipstickEnding = getShiftResult?.shift.isReadingDipstickEnding;

      const shiftDetailsResult = getShiftDetailFields.map((field) => ({
        [field]:
          field === "shiftNumber" ? getShiftResult?.shift[field] : !getShiftResult?.shift[field],
      }));

      shiftDetailsResult.forEach((item) => {
        const key = Object.keys(item)[0];

        state[key] = {
          value: item[key],
          disabled: false,
          required: key === "shiftNumber" && pageMode === FormMode.Edit,
        };
      });

      const dipstickResult = getDipstickFields.map((field) => ({
        [field]: parseFloat(getShiftResult?.shift[field]).toFixed(3),
      }));

      dipstickResult.forEach((item) => {
        const key = Object.keys(item)[0];
        if (
          [
            "beginningDiesel",
            "beginningGas91",
            "beginningGas95",
            "beginningGas97",
            "beginningDieselCm",
            "beginningGas91Cm",
            "beginningGas95Cm",
            "beginningGas97Cm",
          ].includes(key)
        ) {
          state[key] = {
            value: isReadingDipstickBeginning ? item[key] : "",
            disabled: !isReadingDipstickBeginning,
            required: !isReadingDipstickBeginning,
            dirty: !isReadingDipstickBeginning,
          };
        } else if (
          [
            "endingDiesel",
            "endingGas91",
            "endingGas95",
            "endingGas97",
            "endingDieselCm",
            "endingGas91Cm",
            "endingGas95Cm",
            "endingGas97Cm",
          ].includes(key)
        ) {
          state[key] = {
            value: isReadingDipstickEnding ? item[key] : "",
            disabled: !isReadingDipstickEnding,
            required: !isReadingDipstickEnding,
            dirty: !isReadingDipstickEnding,
          };
        } else {
          state[key] = {
            value: item[key],
            disabled: false,
            required: true,
          };
        }
      });

      const shiftEmployees = getShiftResult?.shift.employees;

      if (shiftEmployees) {
        const newEmployees = shiftEmployees.map((i) => {
          i.nameCheck = false;
          i.agencyCheck = false;
          i.labelCheck = false;
          return i;
        });

        setEmployees(sortBy(newEmployees, "shiftEmployeeId"));
      }
    }
    return state;
  }, [getShiftResult, pageMode]);

  const form = useMemo(() => {
    let initialState = transformData || {};
    return formState(initialState);
  }, [transformData]);

  const formData = useForm({
    initialState: form,
  });

  const { isFormSubmittable, getFormValues } = formData;

  const shift = getShiftResult?.shift;

  const totalTransactionCount = shift?.totalTransactionCount || 0;
  const totalBosTransactionCount = shift?.totalBosTransactionCount || 0;
  const progress = calculateSyncProgress({
    totalBosTransactionCount: totalBosTransactionCount,
    totalTransactionCount,
  });

  const onEditDetails = () => {
    history.push(Path.ViewShiftDetailsInfoById(shiftId, FormMode.Edit));
  };

  const submitForm = useCallback(async () => {
    try {
      const getFields = [
        "beginningDiesel",
        "beginningGas91",
        "beginningGas95",
        "beginningGas97",
        "beginningDieselCm",
        "beginningGas91Cm",
        "beginningGas95Cm",
        "beginningGas97Cm",
        "endingDiesel",
        "endingGas91",
        "endingGas95",
        "endingGas97",
        "endingDieselCm",
        "endingGas91Cm",
        "endingGas95Cm",
        "endingGas97Cm",
      ];

      const params = getFormValues();

      getFields.forEach((field) => {
        if (params[field]) {
          params[field] = parseFloat(params[field]?.replace(/,/g, ""));
        } else {
          delete params[field];
        }
      });

      if (employees && employees.length > 0) {
        params.employees = employees;

        if (employeesToPay && employeesToPay.length) {
          params.employees = params.employees.map((i) => {
            const result = employeesToPay.find((ep) => ep.value === i.name);
            let shoulderAmount = 0;

            if (result && result.shoulderAmount) {
              shoulderAmount = stringToDecimal(String(result.shoulderAmount));
            }

            return {
              ...i,
              shoulderAmount,
            };
          });
        }
      }

      if (safeDrop) {
        const newSafeDrop = {};
        let totalAmount = 0;
        safeDrop.forEach((i) => {
          totalAmount = Number(
            Big(totalAmount).add(Number(Big(i.denomination).mul(Number(i.safeDrop))))
          );
          newSafeDrop[i.key] = i.safeDrop;
        });

        newSafeDrop.safeDropId = safeDropId;

        newSafeDrop.amount = totalAmount;

        params.safeDrop = newSafeDrop;
      }

      params.isReadingDipstickBeginning = !beginDidNotPerformDipStick;
      params.isReadingDipstickEnding = !endDidNotPerformDipStick;

      await updateShift.request({
        ...params,
      });

      successModal.show();
    } catch (e) {
      e.showError();
    }
  }, [
    getFormValues,
    employees,
    safeDrop,
    beginDidNotPerformDipStick,
    endDidNotPerformDipStick,
    updateShift,
    successModal,
    employeesToPay,
    safeDropId,
  ]);

  const isShiftEndedCompletely =
    getShiftResult &&
    [ShiftStatus.Ended, ShiftStatus.Closed].includes(getShiftResult?.shift.status) &&
    progress >= 100;

  const showEditButton = useCallback(() => {
    return getShiftResult && getShiftResult?.shift.status === ShiftStatus.Ended && progress >= 100;
  }, [getShiftResult, progress]);

  const showCloseShiftButton = () => {
    return getShiftResult && getShiftResult?.shift.status === ShiftStatus.Ended;
  };

  const isValidEmployees = useMemo(() => {
    return every(employees, { nameCheck: false, labelCheck: false, agencyCheck: false });
  }, [employees]);

  const totalShoulderAmount = useMemo(() => {
    let totalShoulderAmount = 0;
    employeesToPay.forEach((i) => {
      totalShoulderAmount = Number(
        Big(totalShoulderAmount).add(parseNumber(i.shoulderAmount) || 0)
      );
    });

    return totalShoulderAmount;
  }, [employeesToPay]);

  const isShowEditShift =
    pageMode === FormMode.Edit && showCloseShiftButton()
      ? locale.closeShift
      : pageMode === FormMode.View && showEditButton()
      ? locale.editDetails
      : null;

  const isActionDisabled = () => {
    if (pageMode === FormMode.View) {
      return false;
    } else {
      const isVarianceNegative = Big(variance).lt(0);
      const isVarianceExceedingShoulder = Big(Math.abs(variance)).gt(totalShoulderAmount);

      const transactionProgress =
        totalShoulderAmount !== 0 && isVarianceNegative && isVarianceExceedingShoulder;

      return !isValidEmployees || !isFormSubmittable || transactionProgress;
    }
  };

  useEffect(() => {
    if (pageMode === FormMode.Edit && getShiftResult && !showEditButton()) {
      history.push(Path.ViewShiftDetailsInfoById(shiftId, FormMode.View));
    }
  }, [showEditButton, pageMode, getShiftResult, history, shiftId]);

  return (
    <>
      <Loader open={loadingShift} />
      <SuccessModal
        image={StocqClockIn}
        title={locale.closeShift}
        content={locale.successMessageModalCloseShift}
        {...successModal}
        close={() => {
          successModal.close();
        }}
        primary={{
          text: locale.gotIt,
          onClick: () => {
            successModal.close();
            history.push(Path.ShiftRecords);
          },
        }}
      />
      {getShiftResult && !loadingShift && (
        <div>
          <BackLink text={locale.shiftRecords} path={Path.ShiftRecords} />
          <Intro
            title={locale.shiftReportDetails}
            actionText={isShowEditShift}
            actionDisabled={isActionDisabled()}
            actionLoading={updateShift?.loading}
            actionOnClick={() => {
              pageMode === FormMode.View ? onEditDetails() : submitForm();
            }}
          />
          <div style={{ marginBottom: "30px", marginTop: "30px" }}>
            <PanelButtons items={items} isLink={false} page={page} />
          </div>
          <div>
            {page === "info" && (
              <ShiftRecordsDetailsInfoModule
                station={station}
                shift={getShiftResult}
                pageMode={pageMode}
                employees={employees}
                setEmployees={setEmployees}
                form={formData}
              />
            )}
            {page === "cash-noncash" && (
              <ShiftRecordsDetailsCashAndNonCashModule
                shift={getShiftResult}
                pageMode={pageMode}
                setSafeDrop={setSafeDrop}
                safeDrop={safeDrop}
                employees={employees}
                setEmployees={setEmployees}
                employeesToPay={employeesToPay}
                setEmployeesToPay={setEmployeesToPay}
                variance={variance}
                setVariance={setVariance}
              />
            )}
            {page === "sales-inventory" && (
              <ShiftRecordsDetailsSalesInventoryModule
                isShiftEndedCompletely={isShiftEndedCompletely}
                shift={getShiftResult}
                progress={progress}
                pageMode={pageMode}
                form={formData}
                beginDidNotPerformDipStick={beginDidNotPerformDipStick}
                setBeginDidNotPerformDipStick={setBeginDidNotPerformDipStick}
                endDidNotPerformDipStick={endDidNotPerformDipStick}
                setEndDidNotPerformDipStick={setEndDidNotPerformDipStick}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ShiftRecordsDetailsModule;
