import locale from "localization/en";

export const salesTransactionColumns = [
  { key: "date", text: locale.dateAndTime },
  { key: "salesInvoiceNo", text: locale.salesInvoiceNo },
  { key: "customerType", text: locale.customerType },
  { key: "customerId", text: locale.customerId },
  { key: "customerName", text: locale.customerName },
  { key: "grossAmount", text: locale.grossAmount },
  { key: "totalDiscount", text: locale.totalDiscount },
  { key: "vatSales", text: locale.vatSales },
  { key: "vatAmount", text: locale.vatAmount },
  { key: "vatExempt", text: locale.vatExempt },
  { key: "zeroRatedSales", text: locale.zeroRatedSales },
  { key: "salesDiscount", text: locale.salesDiscount },
  { key: "salesPremium", text: locale.salesPremium },
  { key: "totalAmountDue", text: locale.totalAmountDue },
];
