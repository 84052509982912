const Portal = {
  LOCQ: "locq",
  FLEET: "fleet",
  OILCO: "oilco",
  STATION: "station",
  PRICETRACQ: "pricetracq",
  BOS: "bos",
};

export default Portal;
