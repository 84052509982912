import locale from "localization/en";

export const discountReportColumns = [
  { key: "date", text: locale.dateAndTime },
  { key: "invoiceNo", text: locale.salesInvoiceNo },
  { key: "discount", text: locale.discount },
  { key: "description", text: locale.description, width: "200px" },
  { key: "sku", text: locale.sku },
  { key: "quantity", text: locale.qty },
  { key: "unitPrice", text: locale.unitPrice },
  { key: "discountAmount", text: locale.discountAmount },
];
