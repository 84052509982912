import { Text } from "components/commons";
import { dateFormat, formatAmount } from "utils";
import styles from "./sales-summary.module.scss";

export const mapDataToList = ({ dailyRecords }) => {
  const {
    vatableSales,
    zeroRatedSales,
    vatAmount,
    vatExempt,
    presentAccumulatedSales,
    previousAccumulatedSales,
    presentAccumulatedInvoice,
    previousAccumulatedInvoice,
    counter,
    startTime,
    lessDiscount,
    count,
    netAmount,
    salesDiscount,
    salesPremium,
    seniorDiscount,
    pwdDiscount,
    grossAmount,
  } = dailyRecords;

  return {
    date: (
      <>
        <Text className={styles.td}>{dateFormat(startTime ?? "")}</Text>
      </>
    ),
    vatableSales: <Text>{formatAmount(vatableSales)}</Text>,
    zeroRatedSales: <Text>{formatAmount(zeroRatedSales)}</Text>,
    vatAmount: <Text>{formatAmount(vatAmount)}</Text>,
    vatExempt: <Text>{formatAmount(vatExempt)}</Text>,
    accumulatingSalesBeginning: <Text>{formatAmount(previousAccumulatedSales)}</Text>,
    accumulatingSalesEnding: <Text>{formatAmount(presentAccumulatedSales)}</Text>,
    accumulatingInvoiceBeginning: (
      <Text>{previousAccumulatedInvoice ? previousAccumulatedInvoice : "--"}</Text>
    ),
    accumulatingInvoiceEnding: (
      <Text>{presentAccumulatedInvoice ? presentAccumulatedInvoice : "--"}</Text>
    ),
    count: <Text>{count}</Text>,
    total: <Text>{formatAmount(grossAmount)}</Text>,
    counter: <Text>{counter}</Text>,
    salesDiscount: <Text>{formatAmount(salesDiscount)}</Text>,
    salesPremium: <Text>{formatAmount(salesPremium)}</Text>,
    discount: <Text>{formatAmount(lessDiscount)}</Text>,
    seniorDiscount: <Text>{formatAmount(seniorDiscount)}</Text>,
    pwdDiscount: <Text>{formatAmount(pwdDiscount)}</Text>,
    netTotal: <Text>{formatAmount(netAmount)}</Text>,
  };
};

export const mapFilterToRequest = (filterState) => {
  const { ...fs } = filterState;

  return {
    ...fs,
  };
};
