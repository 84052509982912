import React from "react";
import styles from "./shift-records-filter.module.scss";
import { Field, Filter, DateRange, Autocomplete } from "components/commons";
import { locale } from "localization/en";
import { ShiftStatus } from "enums";
import { prettifyShiftStatus } from "utils/pretty.utils";
import SelectCashiers from "components/field/select-cashiers/select-cashiers";

const ShiftRecordsFilter = ({
  filterState,
  modifyFilter,
  filterCount,
  applyFilter,
  applyClearFilter,
  applyClearSearch,
  resetFilter,
}) => {
  return (
    <div className={styles?.container}>
      <div className={styles?.filter}>
        <Filter
          close={resetFilter}
          clear={applyClearFilter}
          submit={() => {
            applyFilter();
          }}
          filterCount={filterCount}
        >
          <Field
            className={styles.date}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
            label={locale.shiftDate}
          >
            <DateRange
              value={{
                startDate: filterState.startDate,
                endDate: filterState.endDate,
              }}
              onChange={(_, { value }) => {
                const { startDate, endDate } = value;
                modifyFilter({ startDate, endDate });
              }}
            />
          </Field>
          <Field
            className={styles.fieldLabel}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
            label={locale?.cashier}
          >
            <SelectCashiers
              {...filterState?.cashiers}
              isMultiple
              disableAllOption
              allSelectedText={locale.allCashiers}
              selectAllLabel={locale?.all}
              onChange={(name, { value, label, isAll }) => {
                modifyFilter({
                  cashiers: {
                    value,
                    label,
                    isAll,
                  },
                });
              }}
            />
          </Field>
          <Field
            className={styles.filterInput}
            label={locale.status}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              name="statuses"
              hasAll
              multiple
              value={filterState.statuses.value}
              options={[
                {
                  label: prettifyShiftStatus(ShiftStatus.Started),
                  value: ShiftStatus.Started,
                },
                {
                  label: prettifyShiftStatus(ShiftStatus.Ended),
                  value: ShiftStatus.Ended,
                },
                {
                  label: prettifyShiftStatus(ShiftStatus.Closed),
                  value: ShiftStatus.Closed,
                },
              ]}
              onChange={(name, { value, isSelectedAll }) =>
                modifyFilter({ [name]: { value, isSelectedAll } })
              }
            />
          </Field>
        </Filter>
      </div>
    </div>
  );
};

export default ShiftRecordsFilter;
