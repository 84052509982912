import React from "react";
import grid from "styles/grid.module.scss";
import styles from "./shift-details-sales-inventory-dipstick.module.scss";
import classNames from "classnames";
import { prettifyProductCode } from "utils/pretty.utils";
import { ShiftStatus } from "enums";
import { formatVolume } from "utils";
import { Text } from "components/commons";

const DipstickDetails = ({ label, product, shift, progress, isEndingDipstick }) => {
  const shiftCompleted = ({ shift, progress }) => {
    const status = shift?.shift.status;
    return (
      (status === ShiftStatus.Closed || (status === ShiftStatus.Ended && progress === 100)) === true
    );
  };

  const beginningDipstick = {
    diesel: [shift?.shift.beginningDieselCm, shift?.shift.beginningDiesel],
    gas91: [shift?.shift.beginningGas91Cm, shift?.shift.beginningGas91],
    gas95: [shift?.shift.beginningGas95Cm, shift?.shift.beginningGas95],
    gas97: [shift?.shift.beginningGas97Cm, shift?.shift.beginningGas97],
  };

  const endingDipstick = {
    diesel: [shift?.shift.endingDieselCm, shift?.shift.endingDiesel || 0],
    gas91: [shift?.shift.endingGas91Cm, shift?.shift.endingGas91],
    gas95: [shift?.shift.endingGas95Cm, shift?.shift.endingGas95],
    gas97: [shift?.shift.endingGas97Cm, shift?.shift.endingGas97],
  };

  return (
    <div className={grid.row}>
      <div className={styles.column}>
        <Text className={styles.classLabel}>{label}</Text>
      </div>

      <div className={styles.column}>
        <Text className={classNames(styles.classLabel, styles[product])}>
          {prettifyProductCode(product)}
        </Text>
      </div>

      <div className={styles.customcolumn}>
        <Text className={classNames(styles.classDesc)}>
          {shiftCompleted({
            shift: shift,
            progress: progress,
          })
            ? formatVolume(
                !isEndingDipstick ? beginningDipstick[product][0] : endingDipstick[product][0],
                "cm"
              )
            : ""}
        </Text>
      </div>
      {/* <div className={styles.unitcolumn}>
        <Text className={classNames(styles.classDesc)}>{"cm"}</Text>
      </div> */}

      <div className={styles.customcolumn}>
        <Text className={classNames(styles.classDesc)}>
          {shiftCompleted({
            shift: shift,
            progress: progress,
          })
            ? formatVolume(
                !isEndingDipstick ? beginningDipstick[product][1] : endingDipstick[product][1]
              )
            : ""}
        </Text>
      </div>
    </div>
  );
};

export default DipstickDetails;
