import React from "react";
import { Text } from "..";
import grid from "styles/grid.module.scss"
import styles from "./label-and-value.module.scss";

const LabelAndValueRow = ({ label, desc, classLabel, classDesc }) => {
  return (
    <div className={grid.row}>
        <div className={grid.column}>
          <Text className={styles.classLabel}>{label}</Text>
        </div>
        
        <div className={grid.column}>
          <Text className={styles.classDesc}>{desc}</Text>
        </div>
      </div> 
  );
};

export default LabelAndValueRow;
