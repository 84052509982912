import React, { useContext } from "react";
import { Intro } from "components/commons";
import locale from "localization";
import ShiftRecordsFilter from "./shift-records-filter";
import { getShifts } from "apis/shift-records.api";
import { shiftRecordColumns } from "./shift-records-column";
import { initialFilterState } from "./shift-records-filter.state";
import { mapFilterToRequest, mapDataToList } from "./shift-records.mapper";
import useDataTable from "hooks/useDataTable";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import { useRouter } from "hooks";
import { StationContext } from "contexts";

const ShiftRecordsModule = () => {
  const { station } = useContext(StationContext);
  const { history } = useRouter();

  const { filter, table } = useDataTable({
    api: {
      api: getShifts,
      params: {
        page: 1,
        perPage: 10,
        stationCode: station.stationCode,
      },
      mapper: (param) => {
        return param;
      },
    },
    filter: { initialState: initialFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "shifts",
      mapper: (shifts) =>
        mapDataToList({
          shifts,
          history,
        }),
      columns: shiftRecordColumns,
    },
  });

  return (
    <>
      <Intro title={locale.shiftRecords} />
      <ShiftRecordsFilter {...filter} />
      <DataTableV2 {...table} />
    </>
  );
};

export default ShiftRecordsModule;
