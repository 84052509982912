import { dateFormat, timeFormat, formatVolume } from "utils";
import { Text } from "components/commons";
import { formatAmount } from "utils";

export const mapDataToList = ({ transactionItems }) => {
  const {
    processedAt,
    receiptNumber,
    discount,
    name,
    sku,
    quantity,
    originalPricePerItem,
    totalDiscount,
    type,
  } = transactionItems;

  return {
    date: <>
      <Text>{dateFormat(processedAt ?? "")}</Text>
      <Text>{timeFormat(processedAt ?? "", true)}</Text>
    </>,
    invoiceNo: <Text>{receiptNumber ?? ""}</Text>,
    discount: <Text>{discount ?? ""}</Text>,
    description: <Text>{name ?? ""}</Text>,
    sku: <Text>{sku ?? ""}</Text>,
    quantity: <Text>{type === "fuel" ? formatVolume(quantity ?? 0) : quantity}</Text>,
    unitPrice: <Text>{formatAmount(originalPricePerItem ?? 0)}</Text>,
    discountAmount: <Text>{formatAmount(totalDiscount ?? 0)}</Text>,
  };
};

export const mapFilterToRequest = (filterState) => {
  const { ...fs } = filterState;

  return {
    ...fs,
  };
};
