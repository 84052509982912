import React from "react";
import { Route, Switch, Redirect } from "react-router";
import { Path } from "enums";
import ShiftRecordsModule from "modules/shift-records/shift-records.module";

const ShiftRecordsListPage = () => {
  return (
    <Switch>
      <Route exact path={Path.ShiftRecords} component={ShiftRecordsModule} />
      <Redirect to={Path.ShiftRecords} />
    </Switch>
  );
};

export default ShiftRecordsListPage;
