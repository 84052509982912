import locale from "localization";
import styles from "./shift-records-details-sales-inventory.module.scss";
import { Title } from "components/commons";
import { dryStockSalesMapper } from "./shift-records-details-sales-inventory.mapper";
import { dryStockSalesColumn } from "./shift-records-details-sales-inventory-column";
import useDataTable from "hooks/useDataTable";
import { initialFilterState } from "modules/shift-records/shift-records-filter.state";
import DataTableV2 from "components/commons/data-table/data-table-v2";

const ShiftDetailsDryStockSales = ({ shift }) => {
  const { table } = useDataTable({
    value: shift?.transactionItem.nonFuel,
    filter: { initialState: initialFilterState(), mapper: (val) => val },
    table: {
      key: "nonFuel",
      name: "dryStockSales",
      mapper: (val) => dryStockSalesMapper({ val }),
      columns: dryStockSalesColumn,
    },
  });

  return (
    <>
      <Title small black>
        {locale.dryStockSales}
      </Title>
      <div className={styles.spacer10} />
      <div className={styles.tableContainer}>
        <DataTableV2 
          {...table} 
          hideNoSearchResultRender
          tableVersion="v2"
          hidePagination
        />
      </div>
      <div className={styles.spacer50} />
    </>
  );
};

export default ShiftDetailsDryStockSales;