import locale from "localization";
import styles from "./shift-records-details-cash-and-non-cash.module.scss";
import { Title } from "components/commons";
import { keyAccountCollectionMapper } from "././shift-records-details-cash-non-cash.mapper";
import { keyAccountCollectionColumn } from "./shift-records-details-cash-non-cash-column";
import useDataTable from "hooks/useDataTable";
import { initialFilterState } from "modules/shift-records/shift-records-filter.state";
import DataTableV2 from "components/commons/data-table/data-table-v2";

const ShiftDetailsKeyAccount = ({ shift }) => {
  const { table } = useDataTable({
    value: shift?.keyAccountCollection,
    filter: { initialState: initialFilterState(), mapper: (val) => val },
    table: {
      key: "keyAccountCollection",
      name:"keyAccountCollection",
      mapper: (val) => keyAccountCollectionMapper({ val }),
      columns: keyAccountCollectionColumn,
    },
  });

  return (
    <>
      <Title small black>
        {locale.keyAccountCollections}
      </Title>
      <div className={styles.spacer10} />
      <div className={styles.tableContainer}>
        <DataTableV2 
          {...table} 
          hideNoSearchResultRender
          tableVersion="v2"
          hidePagination
        />
      </div>
      <div className={styles.spacer50} />
    </>
  );
};

export default ShiftDetailsKeyAccount;