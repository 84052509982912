import locale from "localization";
import styles from "./shift-records-details-sales-inventory.module.scss";
import { Title } from "components/commons";
import {
  fuelDeliveriesMapper,
  fuelDeliveryProductsMapper,
} from "./shift-records-details-sales-inventory.mapper";
import {
  fuelDeliveriesColumn,
  fuelDeliveryProductsColumn,
} from "./shift-records-details-sales-inventory-column";
import { ProductCodeEnum } from "enums";
import { filter,  sortBy } from "lodash";
import { initialFilterState } from "modules/shift-records/shift-records-filter.state";
import useDataTable from "hooks/useDataTable";
import DataTableV2 from "components/commons/data-table/data-table-v2";

const ShiftDetailsFuelDeliveries = ({ shift }) => {
  let sorted = [];

  let deliveries = shift?.delivery.deliveryProducts.reverse();

  filter(deliveries, (e) => {
    const diesel = e.product === ProductCodeEnum.DIESEL;
    if (diesel) {
      sorted.push(e);
    }
    return diesel;
  });
  filter(deliveries, (e) => {
    const gas91 = e.product === ProductCodeEnum.GAS91;
    if (gas91) {
      sorted.push(e);
    }
    return gas91;
  });
  filter(deliveries, (e) => {
    const gas95 = e.product === ProductCodeEnum.GAS95;
    if (gas95) {
      sorted.push(e);
    }
    return gas95;
  });
  filter(deliveries, (e) => {
    const gas97 = e.product === ProductCodeEnum.GAS97;
    if (gas97) {
      sorted.push(e);
    }
    return gas97;
  });
  sorted = sortBy(sorted, "deliveryId");

  const fuelDeliveriesDataTable = useDataTable({
    value: shift?.delivery.deliveries,
    filter: { initialState: initialFilterState(), mapper: (val) => val },
    table: {
      key: "delivery",
      name: "fuelDeliveries",
      mapper: (val) => fuelDeliveriesMapper({ val }),
      columns: fuelDeliveriesColumn,
    },
  });

  const fuelDeliveryProductsDataTable = useDataTable({
    value: sorted,
    filter: { initialState: initialFilterState(), mapper: (val) => val },
    table: {
      key: "delivery",
      name: "fuelDeliveryProducts",
      mapper: (val) => fuelDeliveryProductsMapper({ val }),
      columns: fuelDeliveryProductsColumn,
    },
  });


  return (
    <>
      <Title small black>
        {locale.fuelDeliveries}
      </Title>
      <div className={styles.spacer10} />
      <div className={styles.tableContainer}>
        <DataTableV2 
          {...fuelDeliveriesDataTable.table} 
          hideNoSearchResultRender
          tableVersion="v2"
          hidePagination
        />
      </div>
      <div className={styles.spacer10} />
      <div className={styles.tableContainer}>
        <DataTableV2 
          {...fuelDeliveryProductsDataTable.table} 
          hideNoSearchResultRender
          tableVersion="v2"
          hidePagination
        />
      </div>
      <div className={styles.spacer50} />
    </>
  );
};

export default ShiftDetailsFuelDeliveries;