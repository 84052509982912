export const locale = {
  stocqBOS: "STOCQ Back Office System",
  bos: "Back Office System",
  startShift: "Shift Start Date & Time",
  endShift: "Shift End Date & Time",
  endOfShift: "End of Shift",
  shiftNumber: "Shift Number",
  cashier: "Cashier",
  totalFuelSales: "Total Fuel Sales",
  shiftStatus: "Shift Status",
  syncProgress: "Sync Progress",
  shiftRecords: "Shift Records",
  shiftDetails: "Shift Details",
  shiftReportDetails: "Shift Report Details",
  salesAndInventory: "Sales and Inventory",
  fuelSales: "Fuel Sales",
  fuelDeliveries: "Fuel Deliveries",
  fuelVariance: "Fuel Variance",
  dryStockSales: "Dry Stock Sales",
  beginningInventory: "Beginning Inventory (Dipstick)",
  endingInventory: "Ending Inventory (Dipstick)",
  didNotPerformDipstickReading: "Did not perform dipstick reading",
  cashAndNonCash: "Cash and Non-Cash",
  shiftDate: "Shift Date",
  shiftTime: "Shift Time",
  shiftAttendance: "Shift Attendance",
  agency: "Agency",
  started: "Started",
  ended: "Ended",
  closed: "Closed",
  crewOnDuty: "Crew On Duty",
  pumpNumber: "Pump Number",
  pumpNo: "Pump No.",
  beginning: "Beginning",
  salesInLiters: "Sales In Liters",
  pumpPrice: "Pump Price",
  pumpDiscount: "Pump Discount",
  beginningTotalizer: "Beginning Totalizer",
  endingTotalizer: "Ending Totalizer",
  otherDiscounts: "Other Discounts",
  finalAmount: "Final Amount",
  totalizerDifference: "Totalizer Difference",
  totalizerVariance: "Totalizer Variance (%)",
  deliveryId: "Delivery ID",
  dateAndTime: "Date and Time",
  poReferenceNumber: "PO Reference Number",
  plateNumber: "Plate Number",
  witnesses: "Witnesses",
  variant: "Variant",
  unitPrice: "Unit Price",
  beforeReceivingCm: "Before Receiving (cm)",
  beforeReceiving: "Before Receiving (L)",
  quantityDelivered: "Quantity Delivered",
  afterReceivingCm: "After Receiving (cm)",
  afterReceiving: "After Receiving (L)",
  variance: "Variance",
  ugt: "UGT",
  quantitySold: "Quantity Sold",
  records: "Records",
  nonSaleTransactions: "Non-Sale Transactions",
  nonCashTransactions: "Non-Cash Transactions",
  cashTransactions: "Cash Transactions",
  expenses: "Expenses",
  cashOnHand: "Cash On Hand",
  netCashSummary: "Net Cash Summary",
  bankDeposits: "Bank Deposits",
  keyAccountCollections: "Key Account Collections",
  discounts: "Discounts",
  discount: "Discount",
  pump: "Pump",
  remarks: "Remarks",
  totalAmount: "Total Amount",
  transactionType: "Transaction Type",
  referenceNo: "Reference No.",
  quantity: "Quantity",
  transactionId: "Transaction ID",
  refName: "Ref Name",
  paymentMethod: "Payment Method",
  expenseId: "Expense ID",
  typeOfExpense: "Type of Expense",
  denomination: "Denomination",
  safeDrops: "Safe Drops",
  depositId: "Deposit ID",
  depositType: "Deposit Type",
  collectionId: "Collection ID",
  collectionDateAndTime: "Collection Date And Time",
  siNumSoa: "SI Number / SOA",
  keyAccountName: "Key Account Name",
  editDetails: "Edit details",
  deactivate: "Deactivate",
  add: "Add",
  sorryNoResultFound: "Sorry, no results found",
  weCouldNotFindAMatchForSearchCriteria: "We could not find a match for your search criteria.",
  pleaseTryADifferentOne: "Please try a different one.",
  cancel: "Cancel",
  pleaseEnterAValidMobileNumber: "Please enter a valid mobile number.",
  pleaseEnterAValidTelephoneNumber: "Please enter a valid telephone number.",
  all: "All",
  allFuelProducts: "All Fuel Products",
  clearAll: "Clear All",
  applyFilters: "Apply Filters",
  name: "Name",
  status: "Status",
  email: "Email",
  filters: "Filters",
  emailAddress: "Email Address",
  password: "Password",
  rememberMe: "Remember Me",
  logIn: "Log In",
  incorrectEmailOrPassword: "Incorrect email or password. Please try again.",
  pleaseEnterAValidEmailAddress: "Please enter a valid email address.",
  amount: "Amount",
  expired: "Expired",
  diesel: "Diesel",
  gas91: "Gas 91",
  gas95: "Gas 95",
  gas97: "Gas 97",
  product: "Product",
  onlyAlphaNumericAllowedAndSpecial:
    "Only Alphanumeric characters and the following special symbol ( - , . , spaces ) are allowed.",
  driverLicenseIdIsInvalid: "This driver license ID is invalid.",
  date: "Date",
  to: "to",
  gotIt: "Got It",
  onlyNumericAreAllowed: "Only numeric characters are allowed.",
  ohSnap: "Oh snap!",
  somethingWentWrongPleaseTryAgain: "Something went wrong. Please try again.",
  tryAgain: "Try Again",
  searchOrSelect: "Search or select",
  salesDiscount: "Sales Discount",
  salesPremium: "Sales Premium",
  regularDiscount: "Regular Discount",
  seniorDiscount: "Senior Discount",
  pwdDiscount: "PWD Discount",
  total: "Total",
  pay: "Pay",
  exclamatedSuccess: "Success!",
  oops: "Oops! Something went wrong",
  pleaseRefreshYourBrowser: "Please refresh your browser",
  backToHomePage: "Back to Home Page",
  okayGotIt: "Okay, got it!",
  use8CharWithMix: "Use 8 or more characters with a mix of letters, numbers and symbols",
  goToLogin: "Go to Login",
  viewDetails: "View Details",
  reportGenerated: "Report is being generated",
  browseYourFile: "Browse Your Files",
  replace: "Replace",
  pleaseSelectCSVFile: "Please select a .csv file",
  downloadSingle: "Download",
  lastEdited: "Last edited on ",
  export: "Export",
  logout: "Logout",
  allCashiers: "All Cashiers",
  currentShift: "Current Shift",
  coins: "Coins",
  closeShift: "Close Shift",
  employee: "Employee",
  addEmployee: "Add Employee",
  employeePosition: "Employee Position",
  employeeAssigned: "Employee Assigned",
  employeeToShoulderShortage: "Employee to Shoulder Shortage",
  amountToShoulder: "Amount to Shoulder",
  actualCashOnHand: "Actual Cash on Hand",
  expectedCashOnHand: "Expected Cash on Hand",
  beginningBalance: "Beginning Balance",
  cashSales: "Cash Sales",
  successMessageModalCloseShift: "You have successfully ended your shift",
  birReport: "BIR Report",
  salesSummaryReport: "Sales Summary Report",
  zReadCount: "Z-Read Counter",
  ending: "Ending",
  beginningAccumulatedSalesInvoice: "Beginning Accumulated Sales Invoice",
  endingAccumulatedSalesInvoice: "Ending Accumulated Sales Invoice",
  beginningAccumulatedGrossSales: "Beginning Accumulated Gross Sales",
  endingAccumulatedGrossSales: "Ending Accumulated Gross Sales",
  salesCount: "Sales Count",
  grossTotal: "Gross Total",
  vatableSales: "VATable Sales",
  vatAmount: "VAT Amount",
  vatExempt: "VAT Exempt",
  zeroRatedSales: "Zero-Rated Sales",
  netTotal: "Net Total",
  employeeOnDuty: "Employee on Duty",
  discountReport: "Discount Report",
  salesTransactioHistoryReport: "Sales Transaction History Report",
  invoiceNo: "Invoice No.",
  description: "Description",
  sku: "SKU",
  qty: "QTY",
  discountAmount: "Discount Amount",
  productDescription: "Product Description",
  invoice: "Invoice",
  arrangeBy: "Arrange By",
  grossSales: "Gross Sales",
  customerType: "Customer Type",
  customerId: "Customer ID",
  customerName: "Customer Name",
  grossAmount: "Gross Amount",
  totalDiscount: "Total Discount",
  vatSales: "VATable Sales",
  totalAmountDue: "Total Amount Due",
  salesInvoiceNo: "Sales Invoice No.",
  transient: "Transient",
  keyAccount: "Key Account",
};

export default Object.freeze(locale);
