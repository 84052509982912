import { dateFormat, timeFormat } from "utils";
import { Text } from "components/commons";
import { formatAmount } from "utils";
import { prettifyCustomerType } from "utils/pretty.utils";

export const mapDataToList = ({ transactions }) => {
  const {
    processedAt,
    receiptNumber,
    customerType,
    customerId,
    refName,
    grossTotalAmount,
    discount,
    vatableSales,
    vatAmount,
    vatExemptSales,
    zeroRatedSales,
    salesDiscount,
    salesPremium,
    totalAmountDue,
  } = transactions;

  return {
    date: (
      <>
        <Text>{dateFormat(processedAt ?? "")}</Text>
        <Text>{timeFormat(processedAt ?? "", true)}</Text>
      </>
    ),
    salesInvoiceNo: <Text>{receiptNumber ?? ""}</Text>,
    customerType: <Text>{prettifyCustomerType(customerType) ?? ""}</Text>,
    customerId: <Text>{customerId ?? ""}</Text>,
    customerName: <Text>{refName ?? ""}</Text>,
    grossAmount: <Text>{formatAmount(grossTotalAmount ?? 0)}</Text>,
    totalDiscount: <Text>{formatAmount(discount ?? 0)}</Text>,
    vatSales: <Text>{formatAmount(vatableSales ?? 0)}</Text>,
    vatAmount: <Text>{formatAmount(vatAmount ?? 0)}</Text>,
    vatExempt: <Text>{formatAmount(vatExemptSales ?? 0)}</Text>,
    zeroRatedSales: <Text>{formatAmount(zeroRatedSales ?? 0)}</Text>,
    salesDiscount: <Text>{formatAmount(salesDiscount ?? 0)}</Text>,
    salesPremium: <Text>{formatAmount(salesPremium) ?? 0}</Text>,
    totalAmountDue: <Text>{formatAmount(totalAmountDue ?? 0)}</Text>,
  };
};

export const mapFilterToRequest = (filterState) => {
  const { ...fs } = filterState;

  return {
    ...fs,
  };
};
