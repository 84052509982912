import { ApiPath } from "enums";
import { Get, Put } from "utils";

export const getShifts = async (query) => {
  const res = await Get(`${ApiPath.ShiftRecords}`, query);
  return res.data;
};

export const getShiftEmployees = async (query) => {
  const res = await Get(`${ApiPath.ShiftRecords}/employee`, query);
  return res.data;
};

export const getShiftByShiftId = async ({ shiftId }) => {
  const res = await Get(`${ApiPath.ShiftRecords}/${shiftId}`);
  return res.data;
};

export const updateShiftById = async ({ shiftId, ...body }) => {
  const res = await Put(`${ApiPath.ShiftRecords}/${shiftId}`, body);
  return res.data;
};
