import { DenominationInput, Text } from "components/commons";
import { formatAmount, dateTimeFormat, formatVolume } from "utils";
import { prettifyPaymentMethod } from "utils/pretty.utils";
import Big from "big.js";
import styles from "./shift-records-details-cash-and-non-cash.module.scss";
import { locale } from "localization/en";
import { formatPumpNumber } from "utils/text.utils";

export const nonSaleTransactionMapper = ({ val }) => {
  const { pumpNumber, product } = val;
  return {
    pump: (
      <>
        <Text className={styles.content}>{pumpNumber ?? "1"}</Text>
      </>
    ),
    product: (
      <>
        <Text className={styles.content}>{product ?? ""}</Text>
      </>
    ),
    transactionType: (
      <>
        <Text className={styles.content}>{"PHP 0.50"}</Text>
      </>
    ),
    referenceNo: (
      <>
        <Text className={styles.content}>{"PHP 5000.20"}</Text>
      </>
    ),
    quantity: (
      <>
        <Text className={styles.content}>{"PHP 5000.20"}</Text>
      </>
    ),
    amount: (
      <>
        <Text className={styles.content}>{"PHP 5000.20"}</Text>
      </>
    ),
    transactionId: (
      <>
        <Text className={styles.content}>{"PHP 5000.20"}</Text>
      </>
    ),
    refName: (
      <>
        <Text className={styles.content}>{"PHP 5000.20"}</Text>
      </>
    ),
    plateNumber: (
      <>
        <Text className={styles.content}>{"PHP 5000.20"}</Text>
      </>
    ),
  };
};

export const nonCashTransactionMapper = ({ val }) => {
  const {
    quantity,
    totalAmount,
    type,
    method,
    transactionId,
    name,
    refNumber,
    pumpNumber,
    refName,
    plateNumber,
  } = val;

  let reference = refName;
  if (reference === "") {
    reference = "--";
  }

  let plateNum = plateNumber;
  if (plateNum === "") {
    plateNum = "--";
  }

  let quantityFormat = type === "fuel" ? formatVolume(quantity ?? 0) : quantity;

  return {
    paymentMethod: (
      <>
        <Text className={styles.content}>{prettifyPaymentMethod(method ?? "")}</Text>
      </>
    ),
    referenceNo: (
      <>
        <Text className={styles.content}>{refNumber ?? ""}</Text>
      </>
    ),
    amount: (
      <>
        <Text className={styles.content}>{formatAmount(totalAmount ?? 0)}</Text>
      </>
    ),
    transactionId: (
      <>
        <Text className={styles.content}>{transactionId ?? ""}</Text>
      </>
    ),
    product: (
      <>
        <Text className={styles.content}>{name ?? "--"}</Text>
      </>
    ),
    pumpNumber: (
      <>
        <Text className={styles.content}>
          {type === "fuel" ? formatPumpNumber(pumpNumber.split(",")[0]) : "--"}
        </Text>
      </>
    ),
    quantity: (
      <>
        <Text className={styles.content}>{quantityFormat}</Text>
      </>
    ),
    refName: (
      <>
        <Text className={styles.content}>{reference ?? "--"}</Text>
      </>
    ),
    plateNumber: (
      <>
        <Text className={styles.content}>{plateNum ?? "--"}</Text>
      </>
    ),
  };
};

export const cashTransactionMapper = ({ val }) => {
  const { type, name, pumpNumber, quantity, totalAmount } = val;

  let quantityFormat = type === "fuel" ? formatVolume(quantity ?? 0) : quantity;

  return {
    product: (
      <>
        <Text className={styles.content}>{name ?? "--"}</Text>
      </>
    ),
    pumpNumber: (
      <>
        <Text className={styles.content}>
          {type === "fuel" ? formatPumpNumber(pumpNumber.split(",")[0]) : "--"}
        </Text>
      </>
    ),
    quantity: (
      <>
        <Text className={styles.content}>{quantityFormat}</Text>
      </>
    ),
    amount: (
      <>
        <Text className={styles.content}>{formatAmount(totalAmount ?? 0)}</Text>
      </>
    ),
  };
};

export const expensesMapper = ({ val }) => {
  const { expenseId, type, syncedAt, amount, remarks } = val;
  return {
    expenseId: (
      <>
        <Text className={styles.content}>{expenseId ?? ""}</Text>
      </>
    ),
    type: (
      <>
        <Text className={styles.content}>{type ?? ""}</Text>
      </>
    ),
    dateAndTime: (
      <>
        <Text className={styles.content}>{dateTimeFormat(syncedAt ?? "")}</Text>
      </>
    ),
    amount: (
      <>
        <Text className={styles.content}>{formatAmount(amount ?? 0)}</Text>
      </>
    ),
    remarks: (
      <>
        <Text className={styles.content}>{remarks ?? ""}</Text>
      </>
    ),
  };
};

export const cashOnHandMapper = ({ val, i, onDenominationChange }) => {
  const { denomination, safeDrop, totalAmount } = val;

  return {
    denomination: (
      <>
        <Text className={styles.content}>
          {denomination === 1 ? locale.coins : formatAmount(denomination)}
        </Text>
      </>
    ),
    safeDrops: (
      <>
        <Text className={styles.content}>{safeDrop}</Text>
      </>
    ),
    endShift: (
      <>
        <DenominationInput
          value={safeDrop}
          onDecrementClick={() => {
            onDenominationChange(i, "dec");
          }}
          onIncrementClick={() => {
            onDenominationChange(i, "inc");
          }}
        />
      </>
    ),
    total: (
      <>
        <Text className={styles.content}>{Number(Big(safeDrop))}</Text>
      </>
    ),
    totalAmount: (
      <>
        <Text className={styles.content}>{formatAmount(totalAmount)}</Text>
      </>
    ),
  };
};

export const netCashSummaryMapper = ({ val }) => {
  const { label, value } = val;
  return {
    label: (
      <>
        <Text className={styles.label}>{label ?? "0"}</Text>
      </>
    ),
    value: (
      <>
        <Text className={styles.content}>{value ?? "0"}</Text>
      </>
    ),
  };
};

export const bankDepositMapper = ({ val }) => {
  const { bankDepositId, depositedAt, amount, type, remarks } = val;
  return {
    depositId: (
      <>
        <Text className={styles.content}>{bankDepositId ?? ""}</Text>
      </>
    ),
    dateAndTime: (
      <>
        <Text className={styles.content}>{dateTimeFormat(depositedAt ?? "")}</Text>
      </>
    ),
    amount: (
      <>
        <Text className={styles.content}>{formatAmount(amount ?? 0)}</Text>
      </>
    ),
    depositType: (
      <>
        <Text className={styles.content}>{type ?? ""}</Text>
      </>
    ),
    remarks: (
      <>
        <Text className={styles.content}>{remarks ?? ""}</Text>
      </>
    ),
  };
};

export const keyAccountCollectionMapper = ({ val }) => {
  const {
    keyAccountCollectionId,
    dateTime,
    amount,
    siNumber,
    keyAccountName,
    paymentMethod,
    remarks,
  } = val;
  return {
    collectionId: (
      <>
        <Text className={styles.content}>{keyAccountCollectionId ?? ""}</Text>
      </>
    ),
    dateTime: (
      <>
        <Text className={styles.content}>{dateTimeFormat(dateTime ?? "")}</Text>
      </>
    ),
    amount: (
      <>
        <Text className={styles.content}>{formatAmount(amount ?? 0)}</Text>
      </>
    ),
    siNumSoa: (
      <>
        <Text className={styles.content}>{siNumber ?? ""}</Text>
      </>
    ),
    keyAccountName: (
      <>
        <Text className={styles.content}>{keyAccountName ?? ""}</Text>
      </>
    ),
    paymentMethod: (
      <>
        <Text className={styles.content}>{prettifyPaymentMethod(paymentMethod ?? "")}</Text>
      </>
    ),
    remarks: (
      <>
        <Text className={styles.content}>{remarks ?? "--"}</Text>
      </>
    ),
  };
};
