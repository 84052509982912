export const initialFilterState = () => {
  return {
    startDate: null,
    endDate: null,
    searchKey: "",
    order: "desc",
    customerType: "",
    page: 1,
    perPage: 10,
  };
};
