import React, { useContext } from "react";
import { Intro } from "components/commons";
import locale from "localization";
import DiscountReportFilter from "./discount-report-filter";
import useDataTable from "hooks/useDataTable";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import { StationContext } from "contexts";
import { getTransactionItemsDiscountByStation } from "apis/transaction-discount";
import { initialFilterState } from "./discount-report-filter.state";
import { mapFilterToRequest, mapDataToList } from "./discount-report.mapper";
import { discountReportColumns } from "./discount-report-column";
import styles from "./discount-report.module.scss";
import { generateReport } from "apis/generate-report.api";
import useExport from "hooks/useExport";

const DiscountReportModule = () => {
  const { station } = useContext(StationContext);

  const { filter, search, table } = useDataTable({
    api: {
      api: getTransactionItemsDiscountByStation,
      params: {
        page: 1,
        perPage: 10,
        stationCode: station.stationCode,
        productType: "fuel",
      },
      mapper: (param) => {
        return param;
      },
    },
    filter: { initialState: initialFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "transactionItems",
      mapper: (transactionItems) => mapDataToList({ transactionItems }),
      columns: discountReportColumns,
    },
  });

  const exports = useExport({
    api: generateReport,
    hasModal: false,
    reportType: "transaction-item-discount",
    mappedFilterState: {
      ...filter?.mappedFilterState,
      stationCode: station.stationCode,
      searchKey: search?.searchKey,
      productType: "fuel",
    },
  });

  return (
    <>
      <Intro title={locale.discountReport} />
      <DiscountReportFilter {...filter} {...search} {...exports} />
      <div className={styles.table}>
        <DataTableV2 {...table} />
      </div>
    </>
  );
};

export default DiscountReportModule;
