import { Checkbox } from "components/commons";
import classNames from "classnames";
import React from "react";
import { useMemo } from "react";
import Text from "../text/text";
import styles from "./table-v2.module.scss";
import { AddCircleRounded } from "@material-ui/icons";
import PopOver from "../pop-over/pop-over";
import EditViewColumns from "./edit-view-columns";

const TableV2 = ({
  hasCustomHeader,
  columns,
  data,
  actionHeaderProps,
  onCheckAll,
  hasColumnPicker,
  onChangeActiveColumns,
  ...props
}) => {
  const renderColumns = useMemo(() => {
    const rowHeader = [];
    columns.forEach((c, i) => {
      if (c.actionHeader) {
        rowHeader.push(
          <th
            key={i}
            style={{ textAlign: c.align || "left", width: c.width }}
            className={classNames(c.className)}
            onMouseDown={() => {
              onCheckAll(!actionHeaderProps?.checked);
            }}
          >
            <Checkbox value={actionHeaderProps?.checked} />
          </th>
        );
      } else {
        rowHeader.push(
          <th
            key={i}
            style={{ textAlign: c.align || "left", width: c.width }}
            className={classNames(c.className, hasCustomHeader ? styles.headerText : styles.headerWithRadius, {
              [`${styles.lastColumnWithColumnPicker}`]: i === columns.length - 1 && hasColumnPicker,
            })}
          >
            {c.text}
            {i === columns.length - 1 && hasColumnPicker ? (
              <div className={styles.modifyColumn}>
                <PopOver
                  className={styles.modifyColumnContent}
                  simple
                  onClose={() => {
                    props.resetActiveColumn();
                  }}
                  content={
                    <EditViewColumns columns={columns} onApply={onChangeActiveColumns} {...props} />
                  }
                >
                  <AddCircleRounded className={classNames("pointer", styles.modifyColumnIcon)} />
                </PopOver>
              </div>
            ) : null}
          </th>
        );
      }
    });
    return rowHeader;
  }, [
    actionHeaderProps?.checked,
    columns,
    onCheckAll,
    hasColumnPicker,
    onChangeActiveColumns,
    props,
    hasCustomHeader
  ]);

  const renderRows = useMemo(() => {
    const rowContent = [];
    data.forEach((row, i) => {
      rowContent.push(
        <tr key={i}>
          {columns.map((c, j) => {
            const content = row.get(c.key);
            return (
              <td key={j}>
                <div
                  style={{ textAlign: c.align, verticalAlign: "top", ...c.style }}
                  className={classNames(c.className)}
                >
                  {typeof content === "string" ? <Text className={styles.content}>{content}</Text> : content}
                </div>
              </td>
            );
          })}
        </tr>
      );
    });
    return rowContent;
  }, [columns, data]);

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr>{renderColumns}</tr>
        </thead>
        <tbody>{renderRows}</tbody>
      </table>
    </div>
  );
};

export default TableV2;
