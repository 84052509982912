import { locale } from "localization/en";
import Validation from "utils/validation.utils";

export const initialState = (state = {}) => {
  const {
    shiftNumber = {
      value: "",
      disabled: false,
    },
    isReadingDipstickBeginning = {
      value: "",
      disabled: false,
    },
    beginningDiesel = {
      value: "",
      disabled: false,
    },
    beginningGas91 = {
      value: "",
      disabled: false,
    },
    beginningGas95 = {
      value: "",
      disabled: false,
    },
    beginningGas97 = {
      value: "",
      disabled: false,
    },
    beginningDieselCm = {
      value: "",
      disabled: false,
    },
    beginningGas91Cm = {
      value: "",
      disabled: false,
    },
    beginningGas95Cm = {
      value: "",
      disabled: false,
    },
    beginningGas97Cm = {
      value: "",
      disabled: false,
    },
    isReadingDipstickEnding = {
      value: "",
      disabled: false,
    },
    endingDiesel = {
      value: "",
      disabled: false,
    },
    endingGas91 = {
      value: "",
      disabled: false,
    },
    endingGas95 = {
      value: "",
      disabled: false,
    },
    endingGas97 = {
      value: "",
      disabled: false,
    },
    endingDieselCm = {
      value: "",
      disabled: false,
    },
    endingGas91Cm = {
      value: "",
      disabled: false,
    },
    endingGas95Cm = {
      value: "",
      disabled: false,
    },
    endingGas97Cm = {
      value: "",
      disabled: false,
    },
  } = state;

  return {
    shiftNumber: {
      name: "shiftNumber",
      label: `${locale.shiftNumber} (e.g. 1, 2A, 2B, 3)`,
      validations: [
        Validation.required({ noMessage: true }),
        Validation.maxlength(2),
        Validation.minlength(1),
      ],
      required: false,
      ...shiftNumber,
    },
    isReadingDipstickBeginning: {
      name: "isReadingDipstickBeginning",
      required: false,
      ...isReadingDipstickBeginning,
    },
    isReadingDipstickEnding: {
      name: "isReadingDipstickEnding",
      required: false,
      ...isReadingDipstickEnding,
    },
    beginningDiesel: {
      name: "beginningDiesel",
      required: true,
      max: 50000,
      validations: [
        Validation.required({ noMessage: true }),
        Validation.max(50000, { isFormatted: true }),
      ],
      ...beginningDiesel,
    },
    beginningGas91: {
      name: "beginningGas91",
      required: true,
      max: 50000,
      validations: [
        Validation.required({ noMessage: true }),
        Validation.max(50000, { isFormatted: true }),
      ],
      ...beginningGas91,
    },
    beginningGas95: {
      name: "beginningGas95",
      required: true,
      max: 50000,
      validations: [
        Validation.required({ noMessage: true }),
        Validation.max(50000, { isFormatted: true }),
      ],
      ...beginningGas95,
    },
    beginningGas97: {
      name: "beginningGas97",
      required: true,
      max: 50000,
      validations: [
        Validation.required({ noMessage: true }),
        Validation.max(50000, { isFormatted: true }),
      ],
      ...beginningGas97,
    },
    beginningDieselCm: {
      name: "beginningDieselCm",
      required: true,
      max: 600,
      validations: [
        Validation.required({ noMessage: true }),
        Validation.max(600, { isFormatted: true }),
      ],
      ...beginningDieselCm,
    },
    beginningGas91Cm: {
      name: "beginningGas91Cm",
      required: true,
      max: 600,
      validations: [
        Validation.required({ noMessage: true }),
        Validation.max(600, { isFormatted: true }),
      ],
      ...beginningGas91Cm,
    },
    beginningGas95Cm: {
      name: "beginningGas95Cm",
      required: true,
      max: 600,
      validations: [
        Validation.required({ noMessage: true }),
        Validation.max(600, { isFormatted: true }),
      ],
      ...beginningGas95Cm,
    },
    beginningGas97Cm: {
      name: "beginningGas97Cm",
      required: true,
      max: 600,
      validations: [
        Validation.required({ noMessage: true }),
        Validation.max(600, { isFormatted: true }),
      ],
      ...beginningGas97Cm,
    },
    endingDiesel: {
      name: "endingDiesel",
      required: true,
      max: 50000,
      validations: [
        Validation.required({ noMessage: true }),
        Validation.max(50000, { isFormatted: true }),
      ],
      ...endingDiesel,
    },
    endingGas91: {
      name: "endingGas91",
      required: true,
      max: 50000,
      validations: [
        Validation.required({ noMessage: true }),
        Validation.max(50000, { isFormatted: true }),
      ],
      ...endingGas91,
    },
    endingGas95: {
      name: "endingGas95",
      required: true,
      max: 50000,
      validations: [
        Validation.required({ noMessage: true }),
        Validation.max(50000, { isFormatted: true }),
      ],
      ...endingGas95,
    },
    endingGas97: {
      name: "endingGas97",
      required: true,
      max: 50000,
      validations: [
        Validation.required({ noMessage: true }),
        Validation.max(50000, { isFormatted: true }),
      ],
      ...endingGas97,
    },
    endingDieselCm: {
      name: "endingDieselCm",
      required: true,
      max: 600,
      validations: [
        Validation.required({ noMessage: true }),
        Validation.max(600, { isFormatted: true }),
      ],
      ...endingDieselCm,
    },
    endingGas91Cm: {
      name: "endingGas91Cm",
      required: true,
      max: 600,
      validations: [
        Validation.required({ noMessage: true }),
        Validation.max(600, { isFormatted: true }),
      ],
      ...endingGas91Cm,
    },
    endingGas95Cm: {
      name: "endingGas95Cm",
      required: true,
      max: 600,
      validations: [
        Validation.required({ noMessage: true }),
        Validation.max(600, { isFormatted: true }),
      ],
      ...endingGas95Cm,
    },
    endingGas97Cm: {
      name: "endingGas97Cm",
      required: true,
      max: 600,
      validations: [
        Validation.required({ noMessage: true }),
        Validation.max(600, { isFormatted: true }),
      ],
      ...endingGas97Cm,
    },
  };
};
