import { DropdownPaginated } from "components/commons";
import React, { useCallback, useContext } from "react";
import locale from "localization";
import { useApi } from "hooks";
import { getShiftEmployees } from "apis/shift-records.api";
import { StationContext } from "contexts";

const SelectCashiers = ({ placeholder = locale.searchOrSelect, disableAllOption, ...props }) => {
  const { station } = useContext(StationContext);
  const {
    request,
    loading,
    result = { shiftEmployees: [], count: 0 },
  } = useApi({
    api: getShiftEmployees,
    pageError: true,
  });

  const fetch = useCallback(
    async (params) => {
      return await request({
        label: "cashier",
        perPage: 999,
        stationCode: station.stationCode,
        ...params,
      });
    },
    [request, station.stationCode]
  );

  return (
    <DropdownPaginated
      {...props}
      request={async (params) => {
        return await fetch(params);
      }}
      total={result?.shiftEmployees?.length}
      loading={loading}
      formatRequest={(result) => {
        const { shiftEmployees = [] } = result || {};
        if (shiftEmployees.length > 0) {
          const options = shiftEmployees.map((d) => {
            return {
              label: d.name,
              value: d.name,
            };
          });
          return options;
        }
        return [];
      }}
      placeholder={placeholder}
      {...(!disableAllOption
        ? {
            allOption: [
              {
                label: "All",
                value: "all",
              },
            ],
          }
        : [])}
    />
  );
};

export default SelectCashiers;
