import locale from "localization";
import grid from "styles/grid.module.scss";
import ProductCode from "enums/product-code";
import { Text, Checkbox } from "components/commons";
import styles from "./shift-records-details-sales-inventory.module.scss";
import DipstickDetails from "./shift-details-sales-inventory-dipstick-details.module";
import DipstickInput from "../shift-details-sales-inventory-dipstick-input";
import React, { useCallback } from "react";
import classNames from "classnames";
import { FormMode } from "enums";
import Validation from "utils/validation.utils";
import { validateField } from "hooks/useForm";
import { roundUp } from "utils";
import { isNumber } from "lodash";

const ShiftDetailsDipstick = ({
  shift,
  progress,
  pageMode,
  form,
  beginDidNotPerformDipStick,
  setBeginDidNotPerformDipStick,
  endDidNotPerformDipStick,
  setEndDidNotPerformDipStick,
}) => {
  const { fields, modifyField, modifyForm } = form;

  // const isDidNotPerform = useCallback(
  //   (name, value) => {
  //     let data = {};
  //     if (name === "isReadingDipstickBeginning") {
  //       data = {
  //         [name]: {
  //           value,
  //         },
  //         [fields?.beginningDiesel?.name]: {
  //           value: "",
  //           dirty: false,
  //           required: !value,
  //           validations: value
  //             ? []
  //             : [
  //                 Validation.required({ noMessage: true }),
  //                 Validation.max(50000, { isFormatted: true }),
  //               ],
  //         },
  //         [fields?.beginningGas91.name]: {
  //           value: "",
  //           dirty: false,
  //           required: !value,
  //           validations: value
  //             ? []
  //             : [
  //                 Validation.required({ noMessage: true }),
  //                 Validation.max(50000, { isFormatted: true }),
  //               ],
  //         },
  //         [fields?.beginningGas95.name]: {
  //           value: "",
  //           dirty: false,
  //           required: !value,
  //           validations: value
  //             ? []
  //             : [
  //                 Validation.required({ noMessage: true }),
  //                 Validation.max(50000, { isFormatted: true }),
  //               ],
  //         },
  //         [fields?.beginningGas97.name]: {
  //           value: "",
  //           dirty: false,
  //           required: !value,
  //           validations: value
  //             ? []
  //             : [
  //                 Validation.required({ noMessage: true }),
  //                 Validation.max(50000, { isFormatted: true }),
  //               ],
  //         },
  //         [fields?.beginningDieselCm.name]: {
  //           value: "",
  //           dirty: false,
  //           required: !value,
  //           validations: value
  //             ? []
  //             : [
  //                 Validation.required({ noMessage: true }),
  //                 Validation.max(600, { isFormatted: true }),
  //               ],
  //         },
  //         [fields?.beginningGas91Cm.name]: {
  //           value: "",
  //           dirty: false,
  //           required: !value,
  //           validations: value
  //             ? []
  //             : [
  //                 Validation.required({ noMessage: true }),
  //                 Validation.max(600, { isFormatted: true }),
  //               ],
  //         },
  //         [fields?.beginningGas95Cm.name]: {
  //           value: "",
  //           dirty: false,
  //           required: !value,
  //           validations: value
  //             ? []
  //             : [
  //                 Validation.required({ noMessage: true }),
  //                 Validation.max(600, { isFormatted: true }),
  //               ],
  //         },
  //         [fields?.beginningGas97Cm.name]: {
  //           value: "",
  //           dirty: false,
  //           required: !value,
  //           validations: value
  //             ? []
  //             : [
  //                 Validation.required({ noMessage: true }),
  //                 Validation.max(600, { isFormatted: true }),
  //               ],
  //         },
  //       };
  //       setBeginDidNotPerformDipStick(value);
  //     } else {
  //       setEndDidNotPerformDipStick(value);
  //       data = {
  //         [name]: {
  //           value,
  //         },
  //         [fields?.endingDiesel?.name]: {
  //           value: "",
  //           dirty: false,
  //           required: !value,
  //           validations: value
  //             ? []
  //             : [
  //                 Validation.required({ noMessage: true }),
  //                 Validation.max(50000, { isFormatted: true }),
  //               ],
  //         },
  //         [fields?.endingGas91.name]: {
  //           value: "",
  //           dirty: false,
  //           required: !value,
  //           validations: value
  //             ? []
  //             : [
  //                 Validation.required({ noMessage: true }),
  //                 Validation.max(50000, { isFormatted: true }),
  //               ],
  //         },
  //         [fields?.endingGas95.name]: {
  //           value: "",
  //           dirty: false,
  //           required: !value,
  //           validations: value
  //             ? []
  //             : [
  //                 Validation.required({ noMessage: true }),
  //                 Validation.max(50000, { isFormatted: true }),
  //               ],
  //         },
  //         [fields?.endingGas97.name]: {
  //           value: "",
  //           dirty: false,
  //           required: !value,
  //           validations: value
  //             ? []
  //             : [
  //                 Validation.required({ noMessage: true }),
  //                 Validation.max(50000, { isFormatted: true }),
  //               ],
  //         },
  //         [fields?.endingDieselCm.name]: {
  //           value: "",
  //           dirty: false,
  //           required: !value,
  //           validations: value
  //             ? []
  //             : [
  //                 Validation.required({ noMessage: true }),
  //                 Validation.max(600, { isFormatted: true }),
  //               ],
  //         },
  //         [fields?.endingGas91Cm.name]: {
  //           value: "",
  //           dirty: false,
  //           required: !value,
  //           validations: value
  //             ? []
  //             : [
  //                 Validation.required({ noMessage: true }),
  //                 Validation.max(600, { isFormatted: true }),
  //               ],
  //         },
  //         [fields?.endingGas95Cm.name]: {
  //           value: "",
  //           dirty: false,
  //           required: !value,
  //           validations: value
  //             ? []
  //             : [
  //                 Validation.required({ noMessage: true }),
  //                 Validation.max(600, { isFormatted: true }),
  //               ],
  //         },
  //         [fields?.endingGas97Cm.name]: {
  //           value: "",
  //           dirty: false,
  //           required: !value,
  //           validations: value
  //             ? []
  //             : [
  //                 Validation.required({ noMessage: true }),
  //                 Validation.max(600, { isFormatted: true }),
  //               ],
  //         },
  //       };
  //     }

  //     modifyForm(data);
  //   },
  //   [
  //     fields?.beginningDiesel?.name,
  //     fields?.beginningDieselCm.name,
  //     fields?.beginningGas91.name,
  //     fields?.beginningGas91Cm.name,
  //     fields?.beginningGas95.name,
  //     fields?.beginningGas95Cm.name,
  //     fields?.beginningGas97.name,
  //     fields?.beginningGas97Cm.name,
  //     fields?.endingDiesel?.name,
  //     fields?.endingDieselCm.name,
  //     fields?.endingGas91.name,
  //     fields?.endingGas91Cm.name,
  //     fields?.endingGas95.name,
  //     fields?.endingGas95Cm.name,
  //     fields?.endingGas97.name,
  //     fields?.endingGas97Cm.name,
  //     modifyForm,
  //     setBeginDidNotPerformDipStick,
  //     setEndDidNotPerformDipStick,
  //   ]
  // );

  const isDidNotPerform = useCallback(
    (name, value) => {
      let data = {
        [name]: {
          value,
        },
      };

      const dipsticks = ["isReadingDipstickBeginning", "isReadingDipstickEnding"];

      const getDipstickFields = [
        "beginningDiesel",
        "beginningGas91",
        "beginningGas95",
        "beginningGas97",
        "beginningDieselCm",
        "beginningGas91Cm",
        "beginningGas95Cm",
        "beginningGas97Cm",
        "endingDiesel",
        "endingGas91",
        "endingGas95",
        "endingGas97",
        "endingDieselCm",
        "endingGas91Cm",
        "endingGas95Cm",
        "endingGas97Cm",
      ];

      const type = name === dipsticks[0] ? "beginning" : "ending";

      if (type === "beginning") {
        setBeginDidNotPerformDipStick(value);
      } else {
        setEndDidNotPerformDipStick(value);
      }

      getDipstickFields.forEach((key) => {
        if (key.includes(type)) {
          let dipstickField = validateField(
            {
              ...fields[key],
              disabled: value,
              value:
                !fields[key]?.isReadingDipstickBeginning || !fields[key]?.isReadingDipstickEnding
                  ? ""
                  : fields[key]?.value &&
                    roundUp(
                      isNumber(fields[key]?.value)
                        ? fields[key].value
                        : parseFloat(fields[key].value.replace(/,/g, "")),
                      "0.00"
                    ),
              validations: value
                ? []
                : [
                    Validation.required({ noMessage: true }),
                    Validation.min(0, { isFormatted: true }),
                    Validation.max(50000, { isFormatted: true }),
                  ],
            },
            fields
          );

          data = {
            ...data,
            [key]: dipstickField,
          };
        }
      });
      modifyForm({
        ...fields,
        ...data,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fields]
  );

  return (
    <div className={styles.subContainer}>
      <div className={grid.row}>
        <div className={styles.column}>
          {pageMode === FormMode.View && (
            <>
              <Text className={styles.header}>{locale.beginningInventory}</Text>
              {!shift?.shift.isReadingDipstickBeginning && (
                <>
                  <div className={styles.spacer10} />
                  <Text className={styles.content}>{locale.didNotPerformDipstickReading}</Text>
                </>
              )}

              {shift?.shift.isReadingDipstickBeginning && (
                <>
                  <DipstickDetails
                    label={`${locale.ugt}1`}
                    progress={progress}
                    shift={shift}
                    product={ProductCode.DIESEL}
                  />

                  <DipstickDetails
                    label={`${locale.ugt}2`}
                    progress={progress}
                    shift={shift}
                    product={ProductCode.GAS91}
                  />

                  <DipstickDetails
                    label={`${locale.ugt}3`}
                    progress={progress}
                    shift={shift}
                    product={ProductCode.GAS95}
                  />

                  <DipstickDetails
                    label={`${locale.ugt}4`}
                    progress={progress}
                    shift={shift}
                    product={ProductCode.GAS97}
                  />
                </>
              )}

              <div className={styles.spacer50} />
            </>
          )}
        </div>
        <div className={styles.column}>
          {pageMode === FormMode.View && (
            <>
              <Text className={styles.header}>{locale.endingInventory}</Text>
              {!shift?.shift.isReadingDipstickEnding && (
                <>
                  <div className={styles.spacer10} />
                  <Text className={styles.content}>{locale.didNotPerformDipstickReading}</Text>
                </>
              )}
              {shift?.shift.isReadingDipstickEnding && (
                <>
                  <DipstickDetails
                    label={`${locale.ugt}1`}
                    progress={progress}
                    shift={shift}
                    product={ProductCode.DIESEL}
                    isEndingDipstick={true}
                  />

                  <DipstickDetails
                    label={`${locale.ugt}2`}
                    progress={progress}
                    shift={shift}
                    product={ProductCode.GAS91}
                    isEndingDipstick={true}
                  />

                  <DipstickDetails
                    label={`${locale.ugt}3`}
                    progress={progress}
                    shift={shift}
                    product={ProductCode.GAS95}
                    isEndingDipstick={true}
                  />

                  <DipstickDetails
                    label={`${locale.ugt}4`}
                    progress={progress}
                    shift={shift}
                    product={ProductCode.GAS97}
                    isEndingDipstick={true}
                  />
                </>
              )}

              <div className={styles.spacer50} />
            </>
          )}
        </div>
      </div>

      {pageMode === FormMode.Edit && (
        <div className={styles.editSalesContainer}>
          <div className={styles.beginningInventory}>
            <Text className={styles.header}>{locale.beginningInventory}</Text>
            <div className={styles.checkBox}>
              <Checkbox
                {...fields?.isReadingDipstickBeginning}
                onChange={(name, { value }) => {
                  isDidNotPerform(name, value);
                }}
              />
              {"Did not perform dipstick reading"}
            </div>
            <div className={styles.dipstick}>
              <Text className={classNames(styles.classLabel, styles[ProductCode.DIESEL])}>
                {"UGT 1 (Diesel)"}
              </Text>
              <div className={styles.row}>
                <div className={styles.columnFluid}>
                  <DipstickInput
                    fields={fields?.beginningDieselCm}
                    unit={"cm"}
                    placeholder={"e.g 100.000"}
                    modifyField={modifyField}
                    disabled={beginDidNotPerformDipStick}
                  />
                </div>
                <div className={styles.columnFluid}>
                  <DipstickInput
                    fields={fields?.beginningDiesel}
                    unit={"L"}
                    placeholder={"e.g 100.000"}
                    modifyField={modifyField}
                    disabled={beginDidNotPerformDipStick}
                  />
                </div>
              </div>
            </div>

            <div className={styles.dipstick}>
              <Text className={classNames(styles.classLabel, styles[ProductCode.GAS91])}>
                {"UGT 2 (Gas 91)"}
              </Text>
              <div className={styles.row}>
                <div className={styles.columnFluid}>
                  <DipstickInput
                    fields={fields?.beginningGas91Cm}
                    unit={"cm"}
                    placeholder={"e.g 100.000"}
                    modifyField={modifyField}
                    disabled={beginDidNotPerformDipStick}
                  />
                </div>
                <div className={styles.columnFluid}>
                  <DipstickInput
                    fields={fields?.beginningGas91}
                    unit={"L"}
                    placeholder={"e.g 100.000"}
                    modifyField={modifyField}
                    disabled={beginDidNotPerformDipStick}
                  />
                </div>
              </div>
            </div>

            <div className={styles.dipstick}>
              <Text className={classNames(styles.classLabel, styles[ProductCode.GAS95])}>
                {"UGT 3 (Gas 95)"}
              </Text>
              <div className={styles.row}>
                <div className={styles.columnFluid}>
                  <DipstickInput
                    fields={fields?.beginningGas95Cm}
                    unit={"cm"}
                    placeholder={"e.g 100.000"}
                    modifyField={modifyField}
                    disabled={beginDidNotPerformDipStick}
                  />
                </div>
                <div className={styles.columnFluid}>
                  <DipstickInput
                    fields={fields?.beginningGas95}
                    unit={"L"}
                    placeholder={"e.g 100.000"}
                    modifyField={modifyField}
                    disabled={beginDidNotPerformDipStick}
                  />
                </div>
              </div>
            </div>

            <div className={styles.dipstick}>
              <Text className={classNames(styles.classLabel, styles[ProductCode.GAS97])}>
                {"UGT 4 (Gas 97)"}
              </Text>
              <div className={styles.row}>
                <div className={styles.columnFluid}>
                  <DipstickInput
                    fields={fields?.beginningGas97Cm}
                    unit={"cm"}
                    placeholder={"e.g 100.000"}
                    modifyField={modifyField}
                    disabled={beginDidNotPerformDipStick}
                  />
                </div>
                <div className={styles.columnFluid}>
                  <DipstickInput
                    fields={fields?.beginningGas97}
                    unit={"L"}
                    placeholder={"e.g 100.000"}
                    modifyField={modifyField}
                    disabled={beginDidNotPerformDipStick}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.endingInventory}>
            <Text className={styles.header}>{locale.endingInventory}</Text>
            <div className={styles.checkBox}>
              <Checkbox
                {...fields?.isReadingDipstickEnding}
                onChange={(name, { value }) => {
                  isDidNotPerform(name, value);
                }}
              />
              {"Did not perform dipstick reading"}
            </div>
            <div className={styles.dipstick}>
              <Text className={classNames(styles.classLabel, styles[ProductCode.DIESEL])}>
                {"UGT 1 (Diesel)"}
              </Text>
              <div className={styles.row}>
                <div className={styles.columnFluid}>
                  <DipstickInput
                    fields={fields?.endingDieselCm}
                    unit={"cm"}
                    placeholder={"e.g 100.000"}
                    modifyField={modifyField}
                    disabled={endDidNotPerformDipStick}
                  />
                </div>
                <div className={styles.columnFluid}>
                  <DipstickInput
                    fields={fields?.endingDiesel}
                    unit={"L"}
                    placeholder={"e.g 100.000"}
                    modifyField={modifyField}
                    disabled={endDidNotPerformDipStick}
                  />
                </div>
              </div>
            </div>

            <div className={styles.dipstick}>
              <Text className={classNames(styles.classLabel, styles[ProductCode.GAS91])}>
                {"UGT 2 (Gas 91)"}
              </Text>
              <div className={styles.row}>
                <div className={styles.columnFluid}>
                  <DipstickInput
                    fields={fields?.endingGas91Cm}
                    unit={"cm"}
                    placeholder={"e.g 100.000"}
                    modifyField={modifyField}
                    disabled={endDidNotPerformDipStick}
                  />
                </div>
                <div className={styles.columnFluid}>
                  <DipstickInput
                    fields={fields?.endingGas91}
                    unit={"L"}
                    placeholder={"e.g 100.000"}
                    modifyField={modifyField}
                    disabled={endDidNotPerformDipStick}
                  />
                </div>
              </div>
            </div>

            <div className={styles.dipstick}>
              <Text className={classNames(styles.classLabel, styles[ProductCode.GAS95])}>
                {"UGT 3 (Gas 95)"}
              </Text>
              <div className={styles.row}>
                <div className={styles.columnFluid}>
                  <DipstickInput
                    fields={fields?.endingGas95Cm}
                    unit={"cm"}
                    placeholder={"e.g 100.000"}
                    modifyField={modifyField}
                    disabled={endDidNotPerformDipStick}
                  />
                </div>
                <div className={styles.columnFluid}>
                  <DipstickInput
                    fields={fields?.endingGas95}
                    unit={"L"}
                    placeholder={"e.g 100.000"}
                    modifyField={modifyField}
                    disabled={endDidNotPerformDipStick}
                  />
                </div>
              </div>
            </div>

            <div className={styles.dipstick}>
              <Text className={classNames(styles.classLabel, styles[ProductCode.GAS97])}>
                {"UGT 4 (Gas 97)"}
              </Text>
              <div className={styles.row}>
                <div className={styles.columnFluid}>
                  <DipstickInput
                    fields={fields?.endingGas97Cm}
                    unit={"cm"}
                    placeholder={"e.g 100.000"}
                    modifyField={modifyField}
                    disabled={endDidNotPerformDipStick}
                  />
                </div>
                <div className={styles.columnFluid}>
                  <DipstickInput
                    fields={fields?.endingGas97}
                    unit={"L"}
                    placeholder={"e.g 100.000"}
                    modifyField={modifyField}
                    disabled={endDidNotPerformDipStick}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShiftDetailsDipstick;
