import { DateTime } from "enums";
import moment from "moment";

export const dateFormat = (date) => {
  if (date === "" || date === null || date === undefined) {
    return "";
  }
  return moment(date).format("DD MMM yyyy");
};

export const timeFormat = (time, includeSeconds = false) => {
  if (time === "" || time === null || time === undefined) {
    return "";
  }

  return moment(time).format(`hh:mm${includeSeconds ? ':ss' : ''} a`);
};

export const dateTimeFormat = (dateTime) => {
  if (dateTime === "" || dateTime === null || dateTime === undefined) {
    return "";
  }
  return moment(dateTime).format("DD MMM yyyy HH:mm A");
};

export const formatDate = (date, format = DateTime.A) => {
  return moment(date).format(format);
};
