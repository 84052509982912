import React from "react";
import styles from "./shift-records-details-sales-inventory.module.scss";
import { fuelSalesColumn } from "./shift-records-details-sales-inventory-column";
import { Text } from "components/commons";
import { formatAmount, formatVolume } from "utils";
import { sumBy } from "lodash";
import Big from "big.js";
import classNames from "classnames";

const FuelSummary = ({ isShiftEndedCompletely, data, name }) => {
  const totalSalesInLiters = sumBy(data, "quantity") || 0;
  const totalAmount = sumBy(data, "originalTotalAmount") || 0;
  const totalOtherDiscount = sumBy(data, "totalTransactionDiscount") || 0;
  const totalFinalAmount = sumBy(data, "amount") || 0;
  const totalizerDifference = sumBy(data, "totalizerDifference") || 0;
  const totalizerVariance = sumBy(data, "totalizerVariance") || 0;

  const totalizerVariancePercentage =
    totalizerDifference && totalizerDifference !== 0
      ? Number(Big(totalizerVariance).div(totalizerDifference).mul(100)).toFixed(2)
      : 0;

  return (
    <>
      <div className={styles.tableHeader}>
        <Text className={styles.label}>{name}</Text>
      </div>
      <table className={styles.table}>
        <thead>
          <tr>
            {fuelSalesColumn.map((item, i) => {
              return <th key={i}>{item.text}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {data.length !== 0 &&
            data.map((item, i) => {
              const totalizerDifference = item?.totalizerDifference || 0;
              const totalizerVariance = item?.totalizerVariance || 0;
              const totalizerVariancePercentage =
                totalizerDifference && totalizerDifference !== 0
                  ? Number(Big(totalizerVariance).div(totalizerDifference).mul(100)).toFixed(2)
                  : 0;

              let pumpNumber;
              const metadataObj = item.metadata ? JSON.parse(item.metadata) : null;
              if (metadataObj) {
                pumpNumber = metadataObj.split(",")[0].slice(-1);
              }

              return (
                <tr key={i}>
                  <td>
                    <Text className={styles.content}>{pumpNumber ?? "--"}</Text>
                  </td>
                  <td>
                    <Text className={styles.content}>
                      {formatAmount(item.originalPricePerItem ?? 0)}
                    </Text>
                  </td>
                  <td>
                    <Text className={styles.content}>
                      {formatAmount(item.pumpDiscountPerItem ?? 0)}
                    </Text>
                  </td>
                  <td>
                    <Text className={styles.content}>
                      {formatVolume(item.beginningTotalizer ?? 0)}
                    </Text>
                  </td>
                  <td>
                    <Text className={styles.content}>
                      {isShiftEndedCompletely ? formatVolume(item.endingTotalizer ?? 0) : "N/A"}
                    </Text>
                  </td>
                  <td>
                    <Text className={styles.content}>{formatVolume(item.quantity ?? 0)}</Text>
                  </td>
                  <td>
                    <Text className={styles.content}>
                      {formatAmount(item.originalTotalAmount ?? 0)}
                    </Text>
                  </td>
                  <td>
                    <Text className={styles.content}>
                      {formatAmount(item.totalTransactionDiscount ?? 0)}
                    </Text>
                  </td>
                  <td>
                    <Text className={styles.content}>{formatAmount(item.amount ?? 0)}</Text>
                  </td>
                  <td>
                    <Text className={styles.content}>
                      {isShiftEndedCompletely ? formatVolume(item.totalizerDifference ?? 0) : "N/A"}
                    </Text>
                  </td>
                  <td>
                    <Text
                      className={classNames(
                        styles.content,
                        isShiftEndedCompletely && totalizerVariance > 0 && styles.positive,
                        isShiftEndedCompletely && totalizerVariance < 0 && styles.negative
                      )}
                    >
                      {isShiftEndedCompletely
                        ? `${formatVolume(
                            totalizerVariance ?? 0
                          )} (${totalizerVariancePercentage}%)`
                        : "N/A"}
                    </Text>
                  </td>
                </tr>
              );
            })}
          {data.length !== 0 && (
            <tr>
              <td>
                <Text className={styles.label}>{"Total"}</Text>
              </td>
              <td>
                <Text className={styles.content}>{}</Text>
              </td>
              <td>
                <Text className={styles.content}>{}</Text>
              </td>
              <td>
                <Text className={styles.content}>{}</Text>
              </td>
              <td>
                <Text className={styles.content}>{}</Text>
              </td>
              <td>
                <Text className={styles.label}>{formatVolume(totalSalesInLiters)}</Text>
              </td>
              <td>
                <Text className={styles.label}>{formatAmount(totalAmount)}</Text>
              </td>
              <td>
                <Text className={styles.label}>{formatAmount(totalOtherDiscount)}</Text>
              </td>
              <td>
                <Text className={styles.label}>{formatAmount(totalFinalAmount)}</Text>
              </td>
              <td>
                <Text className={styles.label}>
                  {isShiftEndedCompletely ? formatVolume(totalizerDifference) : "N/A"}
                </Text>
              </td>
              <td>
                <Text
                  className={classNames(
                    styles.content,
                    isShiftEndedCompletely && totalizerVariance > 0 && styles.positive,
                    isShiftEndedCompletely && totalizerVariance < 0 && styles.negative
                  )}
                >
                  {isShiftEndedCompletely
                    ? `${formatVolume(totalizerVariance ?? 0)} (${totalizerVariancePercentage}%)`
                    : "N/A"}
                </Text>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {!data.length && (
        <div className={styles.tableRow}>
          <Text className={styles.label}>{"No Results"}</Text>
        </div>
      )}
    </>
  );
};

export default FuelSummary;
